import { Box, Button, Container, Grid, Typography, Card, CardContent } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Kitchen, PhotoCamera, Share, Restaurant } from '@mui/icons-material';

interface Feature {
  icon: React.ReactNode;
  title: string;
  description: string;
}

function Landing() {
  const { loginWithRedirect } = useAuth0();

  const features: Feature[] = [
    {
      icon: <Restaurant />,
      title: 'Smart Kitchen',
      description: 'Keep track of your ingredients, reduce waste, and discover new recipe possibilities'
    },
    {
      icon: <PhotoCamera />,
      title: 'Snap & Track',
      description: 'Take photos of your groceries to update the ingredients available in your kitchen'
    },
    {
      icon: <Kitchen />,
      title: 'Recipe Matching',
      description: 'Find recipes that match the ingredients you have on hand'
    },
    {
      icon: <Share />,
      title: 'Share Recipes',
      description: 'Upload and share your favorite recipes with the MyKtchn community'
    }
  ];

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          backgroundImage: 'url(/hero-image.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'black',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7))',
            zIndex: 1
          }
        }}
      >
        <Container 
          maxWidth="lg"
          sx={{
            py: { xs: 8, md: 12 },
            position: 'relative',
            zIndex: 2,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: 4
          }}
        >
          <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'left' } }}>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{ 
                fontWeight: 700,
                fontSize: { xs: '2.5rem', md: '3.5rem' },
                lineHeight: 1.2,
                mb: 3,
                color: 'white'
              }}
            >
              Turn Your Ingredients
              <br />
              Into{' '}
              <Box 
                component="span" 
                sx={{ 
                  color: '#d84315',
                  textShadow: '1px 1px 0px rgba(0,0,0,0.3)'
                }}
              >
                Delicious Meals
              </Box>
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                mb: 4,
                color: 'rgba(255, 255, 255, 0.9)',
                fontSize: { xs: '1.1rem', md: '1.3rem' },
                maxWidth: '600px',
                marginX: { xs: 'auto', md: 0 }
              }}
            >
              Use AI to easily keep track of your kitchen ingredients and discover recipes you can make using them.
            </Typography>
            <Box 
              sx={{ 
                display: 'flex', 
                gap: 2,
                justifyContent: { xs: 'center', md: 'flex-start' }
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  px: 4,
                  py: 1.5,
                  fontSize: '1.1rem',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    transition: 'all 0.2s'
                  }
                }}
                onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
              >
                Get Started
              </Button>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  px: 4,
                  py: 1.5,
                  fontSize: '1.1rem',
                  color: 'white',
                  borderColor: 'white',
                  '&:hover': {
                    borderColor: 'white',
                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                  }
                }}
                onClick={() => loginWithRedirect()}
              >
                Log In
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Features Section */}
      <Container 
        maxWidth="lg" 
        sx={{ 
          mt: { xs: 4, md: 6 },
          mb: { xs: 6, md: 8 }
        }}
      >
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card 
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  p: 2,
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: 4
                  }
                }}
              >
                <Box sx={{ 
                  color: 'primary.main',
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '& svg': {
                    fontSize: 48
                  }
                }}
                >
                  {feature.icon}
                </Box>
                <CardContent>
                  <Typography 
                    gutterBottom 
                    variant="h5" 
                    component="h2"
                    sx={{
                      fontWeight: 600,
                      mb: 1.5
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{
                      lineHeight: 1.6
                    }}
                  >
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Call to Action */}
      <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
          <Box 
            sx={{ 
              display: 'flex',
              justifyContent: 'center',
              mb: 4
            }}
          >
            <img
              src="/logo.svg"
              alt="MyKtchn Logo"
              style={{
                height: 50,
                width: 'auto'
              }}
            />
          </Box>
          <Typography variant="h4" gutterBottom>
            Ready to Start Cooking?
          </Typography>
          <Typography variant="body1" paragraph sx={{ mb: 4 }}>
            Join MyKtchn today to organize your ingredients, discover recipes you can make, and share your culinary creations.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              px: 4,
              py: 1.5,
              fontSize: '1.1rem',
              '&:hover': {
                transform: 'translateY(-2px)',
                transition: 'all 0.2s'
              }
            }}
            onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
          >
            Sign Up Now
          </Button>
        </Container>
      </Box>
    </Box>
  );
}

export default Landing; 