export function pluralizeUnit(unit: string, quantity: number): string {
  if (!unit) return '';
  
  // Handle special cases where the plural form is irregular or needs special handling
  const specialCases: { [key: string]: string } = {
    'leaf': 'leaves',
    'loaf': 'loaves',
    'knife': 'knives',
    'half': 'halves',
    'shelf': 'shelves',
    'foot': 'feet'
  };

  // Handle units that end in specific patterns
  const patternCases: [RegExp, string][] = [
    [/sh$/, 'shes'],     // dish -> dishes
    [/ch$/, 'ches'],     // bunch -> bunches
    [/x$/, 'xes'],       // box -> boxes
    [/s$/, 's'],         // glass -> glass
    [/[^aeiou]y$/, 'ies'] // berry -> berries
  ];

  // Units that don't change in plural form
  const unchangedUnits = new Set([
    'each',
  ]);

  // Early return for quantity of 1 or less than 1 (but not exactly 1)
  // This ensures "1/2 cup" but "1 1/2 cups"
  if (quantity === 1 || (quantity < 1 && quantity > 0) || unchangedUnits.has(unit.toLowerCase())) {
    return unit;
  }

  const lowerUnit = unit.toLowerCase();

  // Check special cases first
  if (specialCases[lowerUnit]) {
    return specialCases[lowerUnit];
  }

  // Check pattern cases
  for (const [pattern, replacement] of patternCases) {
    if (pattern.test(lowerUnit)) {
      return lowerUnit.replace(pattern, replacement);
    }
  }

  // Default case: just add 's'
  return unit + 's';
}

export function fractionToDecimal(fraction: string): number {
  if (!fraction) return 0;
  
  // If it's already a number, return it
  if (!isNaN(Number(fraction))) {
    return Number(fraction);
  }
  
  // Handle mixed numbers (e.g., "1 1/2")
  const parts = fraction.split(' ');
  if (parts.length === 2) {
    const whole = Number(parts[0]);
    const fractionPart = parts[1];
    const [num, denom] = fractionPart.split('/').map(Number);
    return whole + (num / denom);
  }
  
  // Handle simple fractions (e.g., "1/2")
  const [num, denom] = fraction.split('/').map(Number);
  return num / denom;
}

export function decimalToFraction(decimal: number): string {
  if (isNaN(decimal) || !isFinite(decimal)) return '0';
  
  // Handle whole numbers
  if (Number.isInteger(decimal)) return decimal.toString();
  
  // Common fractions to make output more human-readable
  const commonFractions: [number, string][] = [
    [0.25, '1/4'],
    [0.5, '1/2'],
    [0.75, '3/4'],
    [0.333, '1/3'],
    [0.667, '2/3'],
    [0.2, '1/5'],
    [0.4, '2/5'],
    [0.6, '3/5'],
    [0.8, '4/5'],
    [0.125, '1/8'],
    [0.375, '3/8'],
    [0.625, '5/8'],
    [0.875, '7/8']
  ];

  // Extract whole number part
  const wholePart = Math.floor(decimal);
  const fractionalPart = decimal - wholePart;

  // If the fractional part is very small, return just the whole number
  if (fractionalPart < 0.001) return wholePart.toString();

  // Look for a close match in common fractions
  for (const [value, fraction] of commonFractions) {
    if (Math.abs(fractionalPart - value) < 0.01) {
      return wholePart ? `${wholePart} ${fraction}` : fraction;
    }
  }

  // For other decimals, convert to fraction with denominator up to 16
  const tolerance = 0.001;
  const maxDenominator = 16;
  
  let bestFraction = '';
  let minDiff = fractionalPart;
  
  for (let denominator = 2; denominator <= maxDenominator; denominator++) {
    const numerator = Math.round(fractionalPart * denominator);
    const diff = Math.abs(fractionalPart - numerator / denominator);
    
    if (diff < minDiff) {
      minDiff = diff;
      bestFraction = `${numerator}/${denominator}`;
      
      if (diff < tolerance) break;
    }
  }

  return wholePart ? `${wholePart} ${bestFraction}` : bestFraction;
} 