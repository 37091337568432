import { useState } from 'react';
import { Box, Typography, Button, Fab } from '@mui/material';
import { Add, Kitchen } from '@mui/icons-material';
import { Ingredient } from '../../types/ingredient';
import { ProcessedIngredientsResponse } from '../../types/processedIngredients';
import { useApi } from '../../api/client';
import { EditableIngredientFields } from './EditableIngredientFields';
import { fractionToDecimal } from '../../utils/units';

interface EditableIngredientsProps {
  ingredients: Ingredient[];
  processedIngredients: ProcessedIngredientsResponse;
  onSave: (ingredients: Ingredient[]) => void;
  setProcessedIngredients: (ingredients: ProcessedIngredientsResponse) => void;
  showSnackbar: (message: string, severity: 'success' | 'error' | 'warning' | 'info') => void;
}

export const EditableIngredients: React.FC<EditableIngredientsProps> = ({
  ingredients,
  processedIngredients,
  onSave,
  setProcessedIngredients,
  showSnackbar
}) => {
  const [editedIngredients, setEditedIngredients] = useState<Ingredient[]>(ingredients);
  const api = useApi();

  const updateIngredient = (index: number, field: keyof Ingredient, value: string) => {
    const newIngredients = [...editedIngredients];
    newIngredients[index] = { ...newIngredients[index], [field]: value };
    setEditedIngredients(newIngredients);
  };

  const addIngredient = () => {
    setEditedIngredients([
      ...editedIngredients,
      { id: crypto.randomUUID(), name: '', quantity: '', unit: '' }
    ]);
  };

  const removeIngredient = (index: number) => {
    setEditedIngredients(editedIngredients.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    try {
      // Convert ingredient quantities to numbers and ensure proper format
      const formattedIngredients = editedIngredients.map(ing => {
        const formattedIngredient: { name: string; quantity: string; unit?: string } = {
          name: ing.name.trim(),
          quantity: fractionToDecimal(ing.quantity).toString()
        };
        
        if (ing.unit.trim()) {
          formattedIngredient.unit = ing.unit.trim();
        }
        
        return formattedIngredient;
      });

      await api.addIngredients(formattedIngredients);
      onSave(editedIngredients);
      showSnackbar('Added to your kitchen inventory!', 'success');
    } catch (error) {
      console.error('Error saving ingredients:', error);
      showSnackbar('Failed to add to kitchen inventory', 'error');
    }
  };

  return (
    <Box sx={{ p: { xs: 1, sm: 3 }, position: 'relative' }}>
      <Box 
        sx={{ 
          position: 'sticky',
          top: 16,
          display: 'flex',
          justifyContent: 'flex-end',
          zIndex: 2,
          marginBottom: -4
        }}
      >
        <Fab
          color="primary"
          size="medium"
          onClick={handleSave}
          sx={{ 
            boxShadow: 2,
            '&:hover': {
              '& .MuiTooltip-tooltip': {
                display: 'block'
              }
            }
          }}
          title="Add to My Kitchen Inventory"
        >
          <Kitchen />
        </Fab>
      </Box>

      <Typography variant="h6" sx={{ mb: 2 }}>
        Review Ingredients Before Adding to Kitchen
      </Typography>

      {editedIngredients.map((ingredient, index) => (
        <EditableIngredientFields
          key={ingredient.id}
          ingredient={ingredient}
          availableUnits={processedIngredients.available_units}
          onUpdate={(field, value) => updateIngredient(index, field, value)}
          onDelete={() => removeIngredient(index)}
        />
      ))}

      <Button
        startIcon={<Add />}
        onClick={addIngredient}
        variant="outlined"
        fullWidth
        size="small"
        sx={{ mt: 1 }}
      >
        Add Ingredient
      </Button>
    </Box>
  );
};

export default EditableIngredients; 