import React, { createContext, useContext, useState, useEffect, ReactNode, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from '../api/client';
import { useSnackbar } from '../components/Snackbar';

interface Household {
  id: string;
  name: string;
  role: string;
  is_creator: boolean;
  created_at: string;
}

interface HouseholdMember {
  id: string;
  email: string;
  role: string;
  joined_at: string;
  is_creator: boolean;
}

interface User {
  email: string;
  created_at: string;
  last_login: string;
  households: Household[];
  current_household: {
    id: string;
    members: HouseholdMember[];
  };
}

interface UserContextType {
  user: User | null;
  loading: boolean;
  error: Error | null;
  retry: () => void;
  households: Household[];
  selectedHousehold: Household | null;
  setSelectedHousehold: (household: Household) => Promise<void>;
  refreshUser: () => Promise<void>;
}

const UserContext = createContext<UserContextType>({
  user: null,
  loading: false,
  error: null,
  retry: () => {},
  households: [],
  selectedHousehold: null,
  setSelectedHousehold: async () => {},
  refreshUser: async () => {}
});

const MAX_RETRIES = 5;
const INITIAL_DELAY = 1000; // 1 second initial delay

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { isAuthenticated, isLoading: authLoading } = useAuth0();
  const api = useApi();
  const { showSnackbar } = useSnackbar();

  // Use refs to track state without causing re-renders
  const retryCountRef = useRef(0);
  const retryTimeoutRef = useRef<NodeJS.Timeout>();
  const isMountedRef = useRef(true);
  const isRetryingRef = useRef(false);
  const hasInitializedRef = useRef(false);
  const pendingRequestRef = useRef<Promise<boolean> | null>(null);

  // Cleanup function to prevent memory leaks
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current);
      }
    };
  }, []);

  const fetchUser = async (): Promise<boolean> => {
    try {
      const userData = await api.getOrCreateUser();
      if (isMountedRef.current) {
        setUser(userData as User);
        setError(null);
      }
      return true;
    } catch (error) {
      if (isMountedRef.current) {
        setError(error as Error);
        showSnackbar('Failed to load user data', 'error');
      }
      return false;
    }
  };

  const retryWithBackoff = async () => {
    if (isRetryingRef.current || retryCountRef.current >= MAX_RETRIES) {
      return;
    }

    isRetryingRef.current = true;
    const delay = INITIAL_DELAY * Math.pow(2, retryCountRef.current);
    retryTimeoutRef.current = setTimeout(async () => {
      retryCountRef.current++;
      const success = await fetchUser();
      isRetryingRef.current = false;

      if (!success && retryCountRef.current < MAX_RETRIES) {
        retryWithBackoff();
      }
    }, delay);
  };

  const initializeUser = async () => {
    if (!isAuthenticated || hasInitializedRef.current || pendingRequestRef.current) {
      return;
    }

    setLoading(true);
    pendingRequestRef.current = fetchUser();
    const success = await pendingRequestRef.current;
    pendingRequestRef.current = null;

    if (!success) {
      retryWithBackoff();
    }

    if (isMountedRef.current) {
      setLoading(false);
      hasInitializedRef.current = true;
    }
  };

  useEffect(() => {
    if (!authLoading) {
      initializeUser();
    }
  }, [isAuthenticated, authLoading]);

  const handleSetSelectedHousehold = async (household: Household) => {
    try {
      await api.updateLastSelectedHousehold(household.id);
      const updatedUser = await api.getOrCreateUser();
      if (isMountedRef.current) {
        setUser(updatedUser as User);
      }
    } catch (error) {
      console.error('Error updating selected household:', error);
      showSnackbar('Failed to update selected household', 'error');
    }
  };

  const refreshUser = async () => {
    setLoading(true);
    try {
      const updatedUser = await api.getOrCreateUser();
      if (isMountedRef.current) {
        setUser(updatedUser as User);
      }
    } catch (error) {
      console.error('Error refreshing user data:', error);
      showSnackbar('Failed to refresh user data', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        loading: loading || authLoading,
        error,
        retry: () => {
          retryCountRef.current = 0;
          initializeUser();
        },
        households: user?.households || [],
        selectedHousehold: user?.households.find(h => h.id === user.current_household.id) || null,
        setSelectedHousehold: handleSetSelectedHousehold,
        refreshUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

/**
 * Hook to run an effect when the selected household changes.
 * Similar to useEffect, but automatically includes selectedHousehold.id in dependencies.
 */
export const useHouseholdEffect = (effect: () => void | Promise<void>, deps: React.DependencyList = []) => {
  const { selectedHousehold } = useUser();
  
  useEffect(() => {
    effect();
  }, [selectedHousehold?.id, ...deps]);
}; 