import React, { useState, useRef, Suspense, useEffect, useCallback } from 'react';
import { Typography, Box, Button, IconButton, Card, CardContent, Grid, CircularProgress, Divider, TextField, Tab, Tabs, Paper } from '@mui/material';
import { PhotoCamera, Upload, FolderOpen, AddPhotoAlternate, Delete, Link as LinkIcon } from '@mui/icons-material';
import { useApi } from '../api/client';
import { useSnackbar } from '../components/Snackbar';
import { Recipe, RecipeFile, RecipeListItem } from '../types/recipe';
import { ProcessedRecipe } from '../types/processedRecipe';
import { resizeImage } from '../utils/imageProcessing';
import { ImageUploader } from '../components/shared/ImageUploader';
import { RecipeGrid } from '../components/recipe/RecipeGrid';
import { useNavigate } from 'react-router-dom';
import { fractionToDecimal, decimalToFraction } from '../utils/units';
import { useUser } from '../contexts/UserContext';

const ZoomableImage = React.lazy(() => import('../components/recipe/ZoomableImage').then(module => ({ default: module.default })));
const EditableRecipe = React.lazy(() => import('../components/recipe/EditableRecipe').then(module => ({ default: module.default })));
const RecipeView = React.lazy(() => import('../components/recipe/RecipeView').then(module => ({ default: module.default })));
const PhotoCapture = React.lazy(() => import('../components/PhotoCapture').then(module => ({ default: module.default })));

function Recipes() {
  const [recipeFile, setRecipeFile] = useState<RecipeFile | null>(null);
  const [showCamera, setShowCamera] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [processedRecipe, setProcessedRecipe] = useState<ProcessedRecipe | null>(null);
  const [editingRecipe, setEditingRecipe] = useState<Recipe | null>(null);
  const [recipes, setRecipes] = useState<RecipeListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [uploadMethod, setUploadMethod] = useState<'image' | 'url'>('image');
  const [recipeUrl, setRecipeUrl] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const api = useApi();
  const { showSnackbar } = useSnackbar();
  const apiRef = useRef(api);
  const showSnackbarRef = useRef(showSnackbar);
  const loadingRef = useRef(false);
  const navigate = useNavigate();
  const { selectedHousehold } = useUser();

  // Update refs when dependencies change
  useEffect(() => {
    apiRef.current = api;
    showSnackbarRef.current = showSnackbar;
  }, [api, showSnackbar]);

  // Function to fetch recipes
  const fetchRecipes = useCallback(async () => {
    // Prevent duplicate requests using ref
    if (loadingRef.current || !hasMore) return;
    
    loadingRef.current = true;
    setLoading(true);
    
    try {
      const response = await apiRef.current.getRecipes(page);
      const newRecipes = response.recipes as unknown as RecipeListItem[];
      
      // Only append recipes if we're not on page 0
      if (page === 0) {
        setRecipes(newRecipes);
      } else {
        setRecipes(prevRecipes => [...prevRecipes, ...newRecipes]);
      }
      
      setHasMore(page < response.pagination.total_pages - 1);
    } catch (error) {
      console.error('Error fetching recipes:', error);
      showSnackbarRef.current('Failed to load recipes', 'error');
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  }, [page, hasMore]);

  // Reset and fetch initial recipes when household changes
  useEffect(() => {
    setRecipes([]);
    setPage(0);
    setHasMore(true);
    loadingRef.current = false;
  }, [selectedHousehold?.id]);

  // Fetch recipes when page changes or household changes
  useEffect(() => {
    fetchRecipes();
  }, [page, fetchRecipes]);

  const handleLoadMore = useCallback(() => {
    if (!loadingRef.current && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  }, [hasMore]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type.startsWith('image/') ? 'image' : 'pdf';
      
      if (fileType === 'image') {
        const reader = new FileReader();
        reader.onloadend = () => {
          setRecipeFile({
            id: crypto.randomUUID(),
            url: reader.result as string,
            file: file,
            type: fileType
          });
        };
        reader.readAsDataURL(file);
      } else {
        showSnackbar('Only image files are currently supported', 'warning');
      }
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handlePhotoCapture = (imageUrl: string) => {
    const base64Response = imageUrl.split(',')[1];
    const binaryString = window.atob(base64Response);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'image/jpeg' });

    setRecipeFile({
      id: crypto.randomUUID(),
      url: imageUrl,
      file: blob,
      type: 'image'
    });
  };

  const handleDeleteFile = () => {
    setRecipeFile(null);
    setProcessedRecipe(null);
    setEditingRecipe(null);
  };

  const handleUploadRecipe = async () => {
    if (!recipeFile?.file) {
      showSnackbar('Please add a recipe image', 'warning');
      return;
    }

    setIsUploading(true);
    try {
      const resizedImage = await resizeImage(recipeFile.file);
      const result = await apiRef.current.uploadRecipe(resizedImage);
      
      // Set the processed recipe with original string quantities
      setProcessedRecipe(result);

      // Set the editing recipe with the original string quantities
      setEditingRecipe({
        title: result.title,
        ingredients: result.ingredients.map(i => ({
          ...i,
          // Keep the original quantity string from the API
          quantity: i.quantity || ''
        })),
        instructions: result.instructions,
        is_public: true
      });
      showSnackbar('Recipe processed successfully!', 'success');
    } catch (error) {
      console.error('Upload error:', error);
      showSnackbar('Failed to process recipe. Please try again.', 'error');
    } finally {
      setIsUploading(false);
    }
  };

  const handleUploadRecipeByUrl = async () => {
    if (!recipeUrl) {
      showSnackbar('Please enter a recipe URL', 'error');
      return;
    }

    setIsUploading(true);
    try {
      const processedRecipe = await apiRef.current.uploadRecipeByUrl(recipeUrl);
      
      // Convert ingredient quantities to human-readable fractions
      const recipeWithFractions = {
        ...processedRecipe,
        ingredients: processedRecipe.ingredients.map(i => ({
          ...i,
          quantity: i.quantity ? decimalToFraction(parseFloat(i.quantity)) : ''
        }))
      };
      
      setProcessedRecipe(recipeWithFractions);
      // Clear the URL input
      setRecipeUrl('');
      // Reset recipes state and page before fetching
      setRecipes([]);
      setPage(0);
      setHasMore(true);
      loadingRef.current = false;
      // Refresh the recipe list since we've added a new recipe
      await fetchRecipes();
      showSnackbar('Recipe imported successfully!', 'success');
    } catch (error: any) {
      // Check if the error response indicates the recipe was already imported
      if (error.response?.status === 400 && error.response?.data?.recipe_id) {
        showSnackbar('This recipe has already been imported', 'info');
        // Clear the URL input
        setRecipeUrl('');
        // Redirect to the existing recipe
        navigate(`/recipes/${error.response.data.recipe_id}`);
      } else {
        console.error('Error uploading recipe:', error);
        const errorMessage = error.response?.data?.error || error.message || 'Failed to process recipe URL';
        showSnackbar(errorMessage, 'error');
      }
    } finally {
      setIsUploading(false);
    }
  };

  const handleSaveRecipe = async (updatedRecipe: Recipe) => {
    if (!processedRecipe) return;

    try {
      // Convert ingredient quantities to decimals
      const recipeToSave = {
        ...updatedRecipe,
        ingredients: updatedRecipe.ingredients.map(ingredient => ({
          ...ingredient,
          quantity: fractionToDecimal(ingredient.quantity).toString()
        }))
      };

      // For new recipes (no ID), we need to save to the database
      if (!processedRecipe.id) {
        const { id } = await apiRef.current.addRecipe(recipeToSave);
        
        // Update the processed recipe view with the saved recipe and its ID
    setProcessedRecipe({
          ...processedRecipe,
          id: id.toString(),
      title: updatedRecipe.title,
          ingredients: updatedRecipe.ingredients,
      instructions: updatedRecipe.instructions
    });
    
        // Exit edit mode after initial save
    setEditingRecipe(null);
        
    showSnackbar('Recipe saved successfully!', 'success');
        await fetchRecipes(); // Refresh the recipe list
        return;
      }

      // For existing recipes, update in the database using the ID from processedRecipe
      await apiRef.current.updateRecipe(processedRecipe.id, recipeToSave);
      setEditingRecipe(null);
      showSnackbar('Recipe updated successfully!', 'success');
      await fetchRecipes(); // Refresh the recipe list
    } catch (error) {
      console.error('Error saving recipe:', error);
      showSnackbar('Failed to save recipe', 'error');
    }
  };

  const handleProcessAnother = () => {
    setRecipeFile(null);
    setProcessedRecipe(null);
    setEditingRecipe(null);
  };

  const handleDeleteRecipe = async () => {
    if (!processedRecipe) return;

    try {
      // If we have an ID, use that for deletion, otherwise use file_key
      if (processedRecipe.id) {
        await apiRef.current.deleteRecipe(processedRecipe.id);
      } else if (processedRecipe.file_key) {
        await apiRef.current.deleteRecipe(processedRecipe.file_key);
      } else {
        throw new Error('No recipe ID or file key found');
      }
      
      showSnackbar('Recipe removed successfully', 'success');
      handleProcessAnother(); // Reset the state
      // Refresh the recipe list
      setRecipes([]);
      setPage(0);
      setHasMore(true);
      loadingRef.current = false;
      await fetchRecipes();
    } catch (error) {
      console.error('Delete error:', error);
      showSnackbar('Failed to remove recipe', 'error');
    }
  };

  const handleRecipeClick = (recipe: RecipeListItem) => {
    window.open(`/recipes/${recipe.id}`, '_blank');
  };

  const LoadingFallback = () => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      height: '100%',
      minHeight: '200px'
    }}>
      <CircularProgress />
    </Box>
  );

  return (
    <Box sx={{ 
      p: { xs: 1.5, sm: 3 }
    }}>
      <Typography variant="h4" gutterBottom>
        Add a Recipe
      </Typography>
      <Typography variant="h6" gutterBottom>
        Import a recipe by photo or URL
      </Typography>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={uploadMethod} 
          onChange={(_, newValue) => setUploadMethod(newValue)}
          aria-label="recipe upload method"
        >
          <Tab 
            icon={<PhotoCamera />} 
            iconPosition="start"
            label="Add from Photo" 
            value="image"
          />
          <Tab 
            icon={<LinkIcon />} 
            iconPosition="start"
            label="Add from URL" 
            value="url"
          />
        </Tabs>
      </Box>
      
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        gap: { xs: 1.5, sm: 2 }, 
        mb: 3,
        alignItems: { xs: 'stretch', sm: 'center' },
        justifyContent: { xs: 'flex-start', sm: 'center' }
      }}>
        {!processedRecipe ? (
          <>
            {uploadMethod === 'image' ? (
              <Paper 
                elevation={1}
                sx={{ 
                  p: 2.5,
                  width: '100%',
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderTop: 0,
                  mt: '-1px'
                }}
              >
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2
                }}>
                  <Typography variant="body2" color="text.secondary">
                    Upload a photo of your recipe, and we'll automatically extract the ingredients and instructions.
                  </Typography>
                  
                  <ImageUploader
                    isUploading={isUploading}
                    onFileSelect={handleFileUpload}
                    onCameraClick={() => setShowCamera(true)}
                    onProcessClick={handleUploadRecipe}
                    showProcessButton={!!recipeFile && !processedRecipe}
                    processButtonText="Import Recipe"
                  />
                </Box>
              </Paper>
            ) : (
              <Paper 
                elevation={1}
                sx={{ 
                  p: 2.5,
                  width: '100%',
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderTop: 0,
                  mt: '-1px'
                }}
              >
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2
                }}>
                  <Box sx={{
                    display: 'flex',
                    gap: 1.5,
                    width: '100%'
                  }}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      label="Recipe URL"
                      value={recipeUrl}
                      onChange={(e) => setRecipeUrl(e.target.value)}
                      placeholder="https://example.com/recipe"
                      disabled={isUploading}
                      InputProps={{
                        startAdornment: (
                          <LinkIcon sx={{ color: 'action.active', mr: 1 }} />
                        ),
                      }}
                    />
                    
                    <Button
                      variant="contained"
                      onClick={handleUploadRecipeByUrl}
                      disabled={!recipeUrl || isUploading}
                      startIcon={isUploading ? <CircularProgress size={20} /> : <Upload />}
                      sx={{ 
                        height: '40px', 
                        minWidth: '100px',
                        '&.Mui-disabled': {
                          bgcolor: 'action.disabledBackground',
                          color: 'text.disabled'
                        }
                      }}
                    >
                      {isUploading ? 'Processing...' : 'Import'}
                    </Button>
                  </Box>
                </Box>
              </Paper>
            )}
          </>
        ) : (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddPhotoAlternate />}
              onClick={handleProcessAnother}
              fullWidth
              sx={{ height: { xs: '56px', sm: '40px' } }}
            >
              Import Another Recipe
            </Button>
        )}
      </Box>

      <Suspense fallback={<LoadingFallback />}>
        <PhotoCapture
          open={showCamera}
          onClose={() => setShowCamera(false)}
          onPhotoCapture={handlePhotoCapture}
        />
      </Suspense>

      {(recipeFile || processedRecipe) && (
        <Grid container spacing={3}>
          {recipeFile && (
          <Grid item xs={12} md={processedRecipe ? 6 : 12}>
            <Card sx={{ mb: 3 }}>
              <Suspense fallback={<LoadingFallback />}>
                <ZoomableImage
                  imageUrl={recipeFile.url}
                  onDelete={handleDeleteFile}
                  showDelete={!isUploading && !processedRecipe}
                />
              </Suspense>
            </Card>
          </Grid>
          )}

          {processedRecipe && (
            <Grid item xs={12} md={recipeFile ? 6 : 12}>
              <Card sx={{ 
                height: '100%',
                maxHeight: '80vh',
                overflow: 'auto'
              }}>
                <CardContent>
                  <Suspense fallback={<LoadingFallback />}>
                    {editingRecipe ? (
                      <EditableRecipe
                        recipe={{
                          ...editingRecipe,
                          source_url: processedRecipe.source_url
                        }}
                        processedRecipe={processedRecipe}
                        onSave={handleSaveRecipe}
                        setProcessedRecipe={setProcessedRecipe}
                        showSnackbar={showSnackbar}
                      />
                    ) : (
                      <RecipeView
                        recipe={{
                          id: processedRecipe.id,
                          title: processedRecipe.title,
                          ingredients: processedRecipe.ingredients.map(i => ({
                            ...i,
                            quantity: i.quantity || ''
                          })),
                          instructions: processedRecipe.instructions,
                          image_key: processedRecipe.file_key,
                          image_url: processedRecipe.image_url,
                          is_public: true
                        }}
                        onEdit={() => setEditingRecipe({
                          id: processedRecipe.id,
                          title: processedRecipe.title,
                          ingredients: processedRecipe.ingredients.map(i => ({
                            ...i,
                            quantity: i.quantity || ''
                          })),
                          instructions: processedRecipe.instructions,
                          image_key: processedRecipe.file_key,
                          image_url: processedRecipe.image_url,
                          is_public: true
                        })}
                        onDelete={handleDeleteRecipe}
                      />
                    )}
                  </Suspense>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      )}

      <Divider sx={{ my: 4 }} />
      
      <RecipeGrid
        recipes={recipes}
        loading={loading}
        hasMore={hasMore}
        onLoadMore={handleLoadMore}
        onRecipeClick={handleRecipeClick}
      />
    </Box>
  );
}

export default Recipes; 