import React, { useRef } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { PhotoCamera, Upload, FolderOpen, AddPhotoAlternate } from '@mui/icons-material';

interface ImageUploaderProps {
  isUploading: boolean;
  onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCameraClick: () => void;
  onProcessClick?: () => void;
  showProcessButton?: boolean;
  processButtonText?: string;
  acceptedFileTypes?: string;
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  isUploading,
  onFileSelect,
  onCameraClick,
  onProcessClick,
  showProcessButton = false,
  processButtonText = 'Process',
  acceptedFileTypes = "image/jpeg,image/png,image/gif,image/bmp,image/webp"
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFileSelect(event);
    // Reset the file input value after handling the selection
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      {!isUploading && (
        <Box sx={{ 
          display: 'flex',
          gap: 1.5,
          width: '100%'
        }}>
          <Button
            variant="contained"
            component="label"
            disabled={isUploading}
            fullWidth
            startIcon={
              <>
                <Box sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
                  <AddPhotoAlternate sx={{ fontSize: 20 }} />
                </Box>
                <Box sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                  <FolderOpen sx={{ fontSize: 20 }} />
                </Box>
              </>
            }
          >
            <Box sx={{ display: { xs: 'inline-block', md: 'none' } }}>Add Photo</Box>
            <Box sx={{ display: { xs: 'none', md: 'inline-block' } }}>Select Image</Box>
            <input
              ref={fileInputRef}
              type="file"
              hidden
              accept={acceptedFileTypes}
              onChange={handleFileSelect}
            />
          </Button>
          
          <Button
            variant="contained"
            onClick={onCameraClick}
            disabled={isUploading}
            startIcon={<PhotoCamera />}
            fullWidth
            sx={{ 
              display: { 
                xs: 'none',
                md: 'inline-flex'
              }
            }}
          >
            Take Picture
          </Button>
        </Box>
      )}

      {showProcessButton && (
        <Button
          variant="contained"
          color="primary"
          startIcon={isUploading ? 
            <CircularProgress size={24} sx={{ color: 'primary.contrastText' }} /> : 
            <Upload sx={{ color: 'primary.contrastText' }} />
          }
          onClick={onProcessClick}
          disabled={isUploading}
          fullWidth
          sx={{ 
            height: { xs: '56px', sm: '40px' }
          }}
        >
          {isUploading ? 'Processing...' : processButtonText}
        </Button>
      )}
    </>
  );
};

export default ImageUploader; 