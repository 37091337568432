import { createContext, useContext, useState, ReactNode } from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';

type SnackbarSeverity = 'success' | 'error' | 'warning' | 'info';

interface SnackbarMessage {
  text: string;
  title?: string;
  severity: SnackbarSeverity;
}

interface SnackbarContextType {
  showSnackbar: (message: string | SnackbarMessage, severity?: SnackbarSeverity) => void;
}

const SnackbarContext = createContext<SnackbarContextType>({
  showSnackbar: () => {},
});

export function SnackbarProvider({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<SnackbarMessage>({ text: '', severity: 'info' });

  const showSnackbar = (msg: string | SnackbarMessage, sev: SnackbarSeverity = 'info') => {
    if (typeof msg === 'string') {
      setMessage({
        text: msg,
        severity: sev,
        title: sev === 'error' ? 'Error' : undefined
      });
    } else {
      setMessage({
        ...msg,
        title: msg.severity === 'error' ? (msg.title || 'Error') : msg.title
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={message.severity === 'error' ? null : 6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          severity={message.severity} 
          onClose={handleClose}
          variant="filled"
          sx={{ 
            minWidth: '300px',
            '& .MuiAlert-message': {
              width: '100%'
            }
          }}
        >
          {message.title && <AlertTitle>{message.title}</AlertTitle>}
          {message.text}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}

export const useSnackbar = () => useContext(SnackbarContext); 