import React from 'react';
import { Box, TextField, IconButton, MenuItem } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { pluralizeUnit, fractionToDecimal } from '../../utils/units';
import { BaseIngredient } from '../../types/shared';

interface EditableIngredientFieldsProps {
  ingredient: BaseIngredient;
  availableUnits: string[];
  onUpdate: (field: keyof BaseIngredient, value: string) => void;
  onDelete: () => void;
  error?: string;
  showNotes?: boolean;
}

export const EditableIngredientFields: React.FC<EditableIngredientFieldsProps> = ({
  ingredient,
  availableUnits,
  onUpdate,
  onDelete,
  error,
  showNotes = false
}) => {
  const handleQuantityChange = (value: string) => {
    // Allow empty value for user to type
    if (!value) {
      onUpdate('quantity', '');
      return;
    }

    // Always update with the current input to allow for typing
    onUpdate('quantity', value);

    // Only validate if the input looks complete (contains a number or fraction)
    if (value.match(/\d/)) {
      try {
        // Try to convert to decimal to validate the format
        const decimal = fractionToDecimal(value);
        if (decimal <= 0) {
          onUpdate('quantity', value);
        }
      } catch (e) {
        // If conversion fails, that's okay - we keep the current input to allow user to continue typing
      }
    }
  };

  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: { xs: 1.5, sm: 1 },
        mb: 2,
        pb: { xs: 2, sm: 0 },
        borderBottom: { xs: '1px solid', sm: 'none' },
        borderColor: 'divider',
        '&:last-child': {
          borderBottom: 'none',
          pb: 0
        }
      }}
    >
      <Box sx={{ 
        display: 'flex',
        gap: 1.5,
        width: { xs: '100%', sm: 'auto' }
      }}>
        <TextField
          label="Amount"
          value={ingredient.quantity}
          onChange={(e) => handleQuantityChange(e.target.value)}
          size="small"
          sx={{ width: { xs: '35%', sm: '100px' } }}
          placeholder="e.g. 1 1/2"
          error={!!error}
          helperText={error}
        />
        <TextField
          select
          label="Unit"
          value={ingredient.unit}
          onChange={(e) => onUpdate('unit', e.target.value)}
          size="small"
          sx={{ width: { xs: '35%', sm: '100px' } }}
        >
          <MenuItem value="">None</MenuItem>
          {availableUnits?.map((unit: string) => (
            <MenuItem key={unit} value={unit}>
              {pluralizeUnit(unit, fractionToDecimal(ingredient.quantity || '0') || 2)}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box sx={{ 
        display: 'flex',
        gap: 1,
        flexGrow: 1,
        width: { xs: '100%', sm: 'auto' }
      }}>
        <TextField
          label="Ingredient"
          value={ingredient.name}
          onChange={(e) => onUpdate('name', e.target.value)}
          size="small"
          sx={{ flexGrow: 1 }}
          placeholder="e.g. tomatoes"
        />
        {showNotes && (
          <TextField
            label="Notes"
            value={ingredient.notes || ''}
            onChange={(e) => onUpdate('notes', e.target.value)}
            size="small"
            sx={{ flexGrow: 1 }}
            placeholder="e.g. diced, peeled"
          />
        )}
        <IconButton 
          onClick={onDelete} 
          size="small" 
          className="delete-button"
          sx={{ alignSelf: 'center' }}
        >
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
}; 