import { createTheme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    lighter?: string;
  }
  interface PaletteColor {
    lighter?: string;
  }
  interface PaletteOptions {
    lighter?: string;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#ffc107', // amber
      light: '#fff8e1', // very light amber
      dark: '#ff8f00', // darker amber
      contrastText: '#37474f', // blue-grey
      lighter: 'rgba(255, 193, 7, 0.08)', // For subtle backgrounds
    },
    secondary: {
      main: '#2196f3', // blue
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#ffffff',
      lighter: 'rgba(33, 150, 243, 0.08)', // For subtle backgrounds
    },
    error: {
      main: '#ef5350', // warm red
      light: 'rgba(239, 83, 80, 0.12)',
      lighter: 'rgba(239, 83, 80, 0.08)',
    },
    warning: {
      main: '#ff9800', // orange
      light: '#ffb74d',
      dark: '#f57c00',
    },
    info: {
      main: '#29b6f6', // light blue
      light: '#4fc3f7',
      dark: '#0288d1',
    },
    success: {
      main: '#66bb6a', // fresh green
      light: 'rgba(102, 187, 106, 0.12)',
      lighter: 'rgba(102, 187, 106, 0.08)',
    },
    background: {
      default: '#ffffff', // white
      paper: '#fff8e1', // very light amber
    },
    text: {
      primary: '#37474f', // blue-grey
      secondary: '#546e7a', // lighter blue-grey
      disabled: 'rgba(55, 71, 79, 0.38)', // blue-grey with opacity
    },
    action: {
      active: '#ff8f00', // darker amber
      hover: '#fff8e1', // very light amber
      selected: '#ffe082', // light amber
      disabled: 'rgba(255, 224, 130, 0.5)', // light amber with opacity
      disabledBackground: 'rgba(255, 224, 130, 0.2)', // light amber with lower opacity
    },
    grey: {
      100: '#fff8e1', // very light amber (for consistency)
      400: '#ffe082', // light amber (for consistency)
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: {
      fontFamily: '"DM Sans", sans-serif',
      fontWeight: 700
    },
    h2: {
      fontFamily: '"DM Sans", sans-serif',
      fontWeight: 700
    },
    h3: {
      fontFamily: '"DM Sans", sans-serif',
      fontWeight: 600
    },
    h4: {
      fontFamily: '"DM Sans", sans-serif',
      fontWeight: 600
    },
    h5: {
      fontFamily: '"DM Sans", sans-serif',
      fontWeight: 500
    },
    h6: {
      fontFamily: '"DM Sans", sans-serif',
      fontWeight: 500
    },
    subtitle1: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400
    },
    subtitle2: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400
    },
    body1: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400
    },
    body2: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400
    },
    button: {
      fontFamily: '"DM Sans", sans-serif',
      fontWeight: 500,
      textTransform: 'none'
    },
    caption: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400
    },
    overline: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Inter, sans-serif',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          backgroundColor: '#ffffff', // background.default
          color: '#37474f', // text.primary
          WebkitTapHighlightColor: 'transparent',
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffc107', // primary.main
          color: '#37474f', // primary.contrastText
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.edit-button': {
            backgroundColor: '#2196f3', // Material UI blue
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#1976d2', // Darker blue
            }
          },
          '&.delete-button': {
            backgroundColor: '#f44336', // Material UI red
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#d32f2f', // Darker red
            }
          }
        },
        contained: {
          backgroundColor: '#ffc107', // primary.main
          color: '#37474f', // primary.contrastText
          '&:hover': {
            backgroundColor: '#ff8f00', // primary.dark
          },
          '&.edit-button': {
            backgroundColor: '#2196f3', // Material UI blue
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#1976d2', // Darker blue
            }
          },
          '&.delete-button': {
            backgroundColor: '#f44336', // Material UI red
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#d32f2f', // Darker red
            }
          }
        },
        containedPrimary: {
          backgroundColor: '#ffc107', // primary.main
          color: '#37474f', // primary.contrastText
          '&:hover': {
            backgroundColor: '#ff8f00', // primary.dark
          },
        },
        containedSecondary: {
          backgroundColor: '#2196f3', // secondary.main
          color: '#ffffff', // secondary.contrastText
          '&:hover': {
            backgroundColor: '#1976d2', // secondary.dark
          },
        },
        outlined: {
          color: '#ffc107', // primary.main
          borderColor: '#ffc107', // primary.main
          '&:hover': {
            backgroundColor: '#fff8e1', // action.hover
            borderColor: '#ff8f00', // primary.dark
          },
          '&.edit-button': {
            color: '#2196f3', // Material UI blue
            borderColor: '#2196f3',
            '&:hover': {
              backgroundColor: 'rgba(33, 150, 243, 0.08)', // Light blue background
              borderColor: '#1976d2', // Darker blue
            }
          },
          '&.delete-button': {
            color: '#f44336', // Material UI red
            borderColor: '#f44336',
            '&:hover': {
              backgroundColor: 'rgba(244, 67, 54, 0.08)', // Light red background
              borderColor: '#d32f2f', // Darker red
            }
          }
        },
        outlinedPrimary: {
          color: '#ffc107', // primary.main
          borderColor: '#ffc107', // primary.main
          '&:hover': {
            backgroundColor: '#fff8e1', // action.hover
            borderColor: '#ff8f00', // primary.dark
          },
        },
        text: {
          color: '#ffc107', // primary.main
          '&:hover': {
            backgroundColor: '#fff8e1', // action.hover
          },
          '&.edit-button': {
            color: '#2196f3', // Material UI blue
            '&:hover': {
              backgroundColor: 'rgba(33, 150, 243, 0.08)', // Light blue background
            }
          },
          '&.delete-button': {
            color: '#f44336', // Material UI red
            '&:hover': {
              backgroundColor: 'rgba(244, 67, 54, 0.08)', // Light red background
            }
          }
        },
        textPrimary: {
          color: '#ffc107', // primary.main
          '&:hover': {
            backgroundColor: '#fff8e1', // action.hover
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#fff8e1', // background.paper
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff', // background.default
          '&:hover': {
            boxShadow: '0px 4px 20px rgba(55, 71, 79, 0.1)', // text.primary with opacity
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffe082', // action.selected
        },
        colorPrimary: {
          backgroundColor: '#ffc107', // primary.main
          color: '#37474f', // primary.contrastText
        },
        colorSecondary: {
          backgroundColor: '#2196f3', // secondary.main
          color: '#ffffff', // secondary.contrastText
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#ff8f00', // action.active
          '&:hover': {
            backgroundColor: '#fff8e1', // action.hover
          },
          '&.Mui-disabled': {
            color: 'rgba(255, 224, 130, 0.5)', // action.disabled
          },
          '&.delete-button': {
            color: '#f44336', // Material UI red
            '&:hover': {
              backgroundColor: 'rgba(244, 67, 54, 0.08)', // Light red background
            }
          }
        },
        colorPrimary: {
          color: '#ffc107', // primary.main
          '&:hover': {
            backgroundColor: '#fff8e1', // action.hover
          },
        },
        colorError: {
          color: '#f44336', // Material UI red
          '&:hover': {
            backgroundColor: 'rgba(244, 67, 54, 0.08)', // Light red background
          }
        }
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(255, 224, 130, 0.5)', // action.disabled
            },
            '&:hover fieldset': {
              borderColor: '#37474f', // text.primary
            },
            '&.Mui-focused fieldset': {
              borderColor: '#ffc107', // primary.main
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#37474f', // text.primary
        },
        head: {
          color: '#546e7a', // text.secondary
          fontWeight: 600,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: '#66bb6a', // success.main
          color: '#ffffff',
        },
        standardError: {
          backgroundColor: '#ef5350', // error.main
          color: '#ffffff',
        },
        standardWarning: {
          backgroundColor: '#ff9800', // warning.main
          color: '#37474f', // text.primary
        },
        standardInfo: {
          backgroundColor: '#29b6f6', // info.main
          color: '#ffffff',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#ffe082', // action.selected
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#fff8e1', // action.hover
          },
          '&.Mui-selected': {
            backgroundColor: '#ffe082', // action.selected
            '&:hover': {
              backgroundColor: 'rgba(255, 224, 130, 0.7)', // Slightly darker on hover
            },
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#ffc107', // primary.main
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(55, 71, 79, 0.5)', // text.primary with opacity
        },
      },
    },
  },
} as ThemeOptions); 