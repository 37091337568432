import { useAuth0 } from '@auth0/auth0-react';
import { BaseIngredient, ProcessedIngredient, Warning } from '../types/shared';
import { Recipe, Ingredient, RecipeListItem } from '../types/recipe';
import { ProcessedIngredientsResponse } from '../types/processedIngredients';
import { ProcessedRecipeResponse } from '../types/recipe';
import { Ingredient as IngredientType } from '../types/ingredient';
import { useUser } from '../contexts/UserContext';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'https://api.myktchn.com';

export type RawIngredient = BaseIngredient;

export type { ProcessedIngredient };

export interface ProcessedIngredients {
  ingredients: IngredientType[];
  available_units: string[];
  file_key: string;
  warnings?: Warning[];
}

export interface ProcessedRecipe {
  title: string;
  ingredients: IngredientType[];
  instructions: string[];
  prep_time?: string;
  cook_time?: string;
  servings?: number;
  available_units: string[];
  warnings?: Warning[];
}

export interface User {
  email: string;
  created_at: string;
  last_login: string;
  households: Array<{
    id: string;
    name: string;
    role: string;
    is_creator: boolean;
    created_at: string;
  }>;
  current_household: {
    id: string;
    members: Array<{
      email: string;
      role: string;
      joined_at: string;
      is_creator: boolean;
    }>;
  };
}

export interface InventoryIngredient {
  ingredient_name: string;
  amount: number;
      unit: string;
  last_updated: string;
}

export interface PaginatedInventory {
  ingredients: InventoryIngredient[];
  pagination: {
    total: number;
    page: number;
    per_page: number;
    total_pages: number;
  };
}

interface ApiError {
  error: string;
  status?: number;
}

interface ApiErrorResponse {
  error: string;
}

interface GetIngredientsParams {
  page: number;
  per_page: number;
  sort_by: string;
  sort_order: 'asc' | 'desc';
  search?: string;
}

interface GetIngredientsResponse {
  ingredients: Array<{
    ingredient_name: string;
    amount: number;
    unit: string | null;
    last_updated: string;
  }>;
  available_units: string[];
  pagination: {
    total: number;
    page: number;
    per_page: number;
    total_pages: number;
  };
}

export interface UpdateIngredientParams {
  ingredient_name: string;
  amount: number;
  unit?: string;
}

interface ApiResponse<T> {
  data: T;
}

interface PaginatedResponse<T> {
  recipes: T[];
  pagination: {
    total: number;
    page: number;
    per_page: number;
    total_pages: number;
  };
}

export interface HouseholdMember {
  id: string;
  email: string;
  role: string;
  joined_at: string;
  is_creator: boolean;
}

export interface HouseholdMembersResponse {
  members: HouseholdMember[];
}

export interface Household {
  id: string;
  name: string;
  role: string;
  is_creator: boolean;
  created_at: string;
}

export interface HouseholdsResponse {
  households: Household[];
}

export interface HouseholdInfo {
  households: Household[];
  current_household: {
    id: string;
    members: HouseholdMember[];
  };
}

export interface ApiClient {
  uploadIngredients: (file: File | Blob) => Promise<ProcessedIngredientsResponse>;
  uploadRecipe: (file: File | Blob) => Promise<ProcessedRecipeResponse>;
  uploadRecipeByUrl: (url: string) => Promise<ProcessedRecipeResponse>;
  addIngredients: (ingredients: Array<{ name: string; quantity: string; unit?: string }>) => Promise<void>;
  addRecipe: (recipe: Omit<Recipe, 'id' | 'image_key' | 'is_creator'>) => Promise<{ id: string }>;
  getRecipes: (page?: number, perPage?: number) => Promise<{ recipes: Recipe[]; pagination: { total_pages: number } }>;
  getRecipe: (recipeId: string) => Promise<Recipe>;
  processRecipe: (recipe: Omit<Recipe, 'id' | 'image_key' | 'is_creator'>) => Promise<ProcessedRecipeResponse>;
  updateRecipe: (recipeId: string, recipe: Omit<Recipe, 'id' | 'image_key' | 'is_creator'>) => Promise<void>;
  deleteRecipe: (recipeId: string) => Promise<void>;
  getIngredients: (params: GetIngredientsParams) => Promise<GetIngredientsResponse>;
  updateIngredient: (params: UpdateIngredientParams) => Promise<void>;
  deleteIngredient: (ingredient_name: string) => Promise<void>;
  inviteHouseholdMember: (email: string, role: 'member' | 'admin') => Promise<void>;
  removeHouseholdMember: (memberId: string) => Promise<void>;
  updateHouseholdName: (name: string) => Promise<void>;
  updateLastSelectedHousehold: (household_id: string) => Promise<void>;
  getOrCreateUser: () => Promise<User>;
}

export function useApi(): ApiClient {
  const { getAccessTokenSilently } = useAuth0();
  const { selectedHousehold } = useUser();

  const getHeaders = async () => {
      const token = await getAccessTokenSilently();
    return {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
  };

  const get = async (url: string) => {
    const headers = await getHeaders();
    const response = await fetch(`${API_BASE_URL}${url}`, {
      method: 'GET',
      headers
    });
    
    const responseData = await response.json();
    
    if (!response.ok) {
      const error = new Error('API request failed') as any;
      error.response = {
        status: response.status,
        data: responseData
      };
      throw error;
    }
    
    return responseData;
  };

  const post = async (url: string, data?: any) => {
    const headers = await getHeaders();
    const response = await fetch(`${API_BASE_URL}${url}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    });
    
    const responseData = await response.json();
    
    if (!response.ok) {
      const error = new Error('API request failed') as any;
      error.response = {
        status: response.status,
        data: responseData
      };
      throw error;
    }
    
    return responseData;
  };

  const put = async (url: string, data?: any) => {
    const headers = await getHeaders();
    const response = await fetch(`${API_BASE_URL}${url}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    });
    
    const responseData = await response.json();
    
    if (!response.ok) {
      const error = new Error('API request failed') as any;
      error.response = {
        status: response.status,
        data: responseData
      };
      throw error;
    }
    
    return responseData;
  };

  const del = async (url: string, data?: any) => {
    const headers = await getHeaders();
    const response = await fetch(`${API_BASE_URL}${url}`, {
      method: 'DELETE',
      headers,
      ...(data && { body: JSON.stringify(data) })
    });
    
    const responseData = await response.json();
    
    if (!response.ok) {
      const error = new Error('API request failed') as any;
      error.response = {
        status: response.status,
        data: responseData
      };
    throw error;
    }
    
    return responseData;
  };

  const handleError = (error: unknown): never => {
    console.error('API Error:', error);
    throw new Error('An unexpected error occurred. Please try again later.');
  };

  const uploadFile = async <T>(endpoint: string, file: File | Blob): Promise<T> => {
    const formData = new FormData();
    formData.append('file', file);

    const headers = await getHeaders();
    const { 'Content-Type': _, ...uploadHeaders } = headers; // Remove Content-Type for form data
    
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: uploadHeaders,
      body: formData
    });
    
    if (!response.ok) {
      throw new Error('Failed to upload file');
    }
    
    const data = await response.json();
    return data as T;
  };

  const uploadIngredients = async (file: File | Blob): Promise<ProcessedIngredientsResponse> => {
    const formData = new FormData();
    formData.append('file', file);
    
    const headers = await getHeaders();
    const { 'Content-Type': _, ...uploadHeaders } = headers; // Remove Content-Type for form data
    
    const response = await fetch(`${API_BASE_URL}/upload-ingredients`, {
      method: 'POST',
      headers: uploadHeaders,
      body: formData
    });
    
    if (!response.ok) {
      throw new Error('Failed to upload ingredients');
    }
    
    const data = await response.json();
    return data as ProcessedIngredientsResponse;
  };

  const uploadRecipe = async (file: File | Blob): Promise<ProcessedRecipeResponse> => {
      const formData = new FormData();
      formData.append('file', file);

    const headers = await getHeaders();
    const { 'Content-Type': _, ...uploadHeaders } = headers; // Remove Content-Type for form data
    
    const response = await fetch(`${API_BASE_URL}/upload-recipe`, {
      method: 'POST',
      headers: uploadHeaders,
      body: formData
    });
    
    if (!response.ok) {
      throw new Error('Failed to upload recipe');
    }
    
    const data = await response.json();
    return data as ProcessedRecipeResponse;
  };

  const uploadRecipeByUrl = async (url: string): Promise<ProcessedRecipeResponse> => {
    const response = await post('/upload-recipe-by-url', { url });
    if (!response) {
      throw new Error('Failed to process recipe URL');
    }
    return response as ProcessedRecipeResponse;
  };

  const addIngredients = async (ingredients: Array<{ name: string; quantity: string; unit?: string }>): Promise<void> => {
    await post('/ingredients', { ingredients });
  };

  const addRecipe = async (recipe: Omit<Recipe, 'id' | 'image_key' | 'is_creator'>): Promise<{ id: string }> => {
    const response = await post('/recipe', recipe);
    return response as { id: string };
  };

  const getRecipes = async (page = 0, perPage = 24): Promise<{ recipes: Recipe[]; pagination: { total_pages: number } }> => {
    const response = await get(`/recipes?page=${page}&per_page=${perPage}`);
    if (!response.recipes || !response.pagination) {
      throw new Error('Invalid API response format');
    }
    return response as { recipes: Recipe[]; pagination: { total_pages: number } };
  };

  const getRecipe = async (recipeId: string): Promise<Recipe> => {
    const response = await get(`/recipe/${recipeId}`);
    return response as Recipe;
  };

  const processRecipe = async (recipe: Omit<Recipe, 'id' | 'image_key' | 'is_creator'>): Promise<ProcessedRecipeResponse> => {
    const response = await post('/api/process_recipe', recipe);
    return response as ProcessedRecipeResponse;
  };

  const updateRecipe = async (recipeId: string, recipe: Omit<Recipe, 'id' | 'image_key' | 'is_creator'>): Promise<void> => {
    await put(`/recipe?id=${recipeId}`, recipe);
  };

  const deleteRecipe = async (recipeId: string): Promise<void> => {
    await del(`/recipe/${recipeId}`);
  };

  const getIngredients = async (params: GetIngredientsParams): Promise<GetIngredientsResponse> => {
    const queryParams = new URLSearchParams();
    if (params.search) queryParams.append('search', params.search);
    if (params.page) queryParams.append('page', params.page.toString());
    if (params.per_page) queryParams.append('per_page', params.per_page.toString());
    
    const response = await get(`/ingredients?${queryParams.toString()}`);
    if (!response.ingredients || !response.pagination) {
      throw new Error('Invalid API response format');
    }
    return response as GetIngredientsResponse;
  };

  const updateIngredient = async (params: UpdateIngredientParams): Promise<void> => {
    await put('/ingredients', params);
  };

  const deleteIngredient = async (ingredient_name: string): Promise<void> => {
    await del('/ingredients', { ingredient_name });
  };

  const inviteHouseholdMember = async (email: string, role: 'member' | 'admin'): Promise<void> => {
    await post('/household/invite', { email, role });
  };

  const removeHouseholdMember = async (memberId: string): Promise<void> => {
    await del(`/household/members/${memberId}`);
  };

  const updateHouseholdName = async (name: string): Promise<void> => {
    await put('/household/name', { name });
  };

  const updateLastSelectedHousehold = async (household_id: string): Promise<void> => {
    await put('/household/last-selected', { household_id });
  };

  const getOrCreateUser = async (): Promise<User> => {
    const response = await get('/user');
    return response;
  };

  const getHouseholdInfo = async (): Promise<HouseholdInfo> => {
    const response = await get('/household');
    return response.data;
  };

  return {
    uploadIngredients,
    uploadRecipe,
    uploadRecipeByUrl,
    addIngredients,
    addRecipe,
    getRecipes,
    getRecipe,
    processRecipe,
    updateRecipe,
    deleteRecipe,
    getIngredients,
    updateIngredient,
    deleteIngredient,
    inviteHouseholdMember,
    removeHouseholdMember,
    updateHouseholdName,
    updateLastSelectedHousehold,
    getOrCreateUser
  };
} 