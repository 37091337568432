import { ThemeProvider, CssBaseline } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { theme } from './theme';
import Layout from './components/Layout';
import { SnackbarProvider } from './components/Snackbar';
import { UserProvider } from './contexts/UserContext';
import Recipes from './pages/Recipes';
import Pantry from './pages/Pantry';
import Landing from './pages/Landing';
import Recipe from './pages/Recipe';
import Household from './pages/Household';

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div>Loading...</div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <UserProvider>
          <Layout>
            <Routes>
              <Route
                path="/"
                element={isAuthenticated ? <Pantry /> : <Landing />}
              />
              <Route
                path="/recipes"
                element={isAuthenticated ? <Recipes /> : <Navigate to="/" replace />}
              />
              <Route
                path="/recipes/:recipeId"
                element={isAuthenticated ? <Recipe /> : <Navigate to="/" replace />}
              />
              <Route
                path="/household"
                element={isAuthenticated ? <Household /> : <Navigate to="/" replace />}
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Layout>
        </UserProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App; 