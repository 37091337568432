import React from 'react';
import { Box } from '@mui/material';
import { MenuBook } from '@mui/icons-material';

interface RecipePlaceholderProps {
  height?: number | string;
}

export const RecipePlaceholder: React.FC<RecipePlaceholderProps> = ({ height = 200 }) => {
  return (
    <Box
      sx={{
        height,
        backgroundColor: 'grey.100',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <MenuBook
        sx={{
          fontSize: height ? Math.min(Number(height) * 0.4, 100) : 80,
          color: 'grey.400'
        }}
      />
    </Box>
  );
}; 