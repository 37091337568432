import React, { useRef, useCallback, memo } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Box, CircularProgress, Skeleton } from '@mui/material';
import { MenuBook } from '@mui/icons-material';
import { RecipeListItem } from '../../types/recipe';
import { RecipePlaceholder } from './RecipePlaceholder';

interface RecipeGridProps {
  recipes: RecipeListItem[];
  loading: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
  onRecipeClick: (recipe: RecipeListItem) => void;
}

// Memoize the recipe card to prevent unnecessary rerenders
const RecipeCard = memo(({ recipe, onClick }: { recipe: RecipeListItem; onClick: () => void }) => (
  <Card 
    sx={{ 
      height: '100%', 
      cursor: 'pointer',
      transition: 'transform 0.2s',
      position: 'relative',
      '&:hover': {
        transform: 'scale(1.02)'
      }
    }}
    onClick={onClick}
  >
    {recipe.image_url ? (
      <CardMedia
        component="img"
        height={200}
        image={recipe.image_url}
        alt={recipe.title}
        sx={{ objectFit: 'cover' }}
      />
    ) : (
      <RecipePlaceholder height={200} />
    )}
    <Box
      sx={{
        position: 'absolute',
        top: 12,
        right: 12,
        bgcolor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        borderRadius: '16px',
        px: 1.5,
        py: 0.5,
        display: 'flex',
        alignItems: 'center',
        gap: 0.5
      }}
    >
      <MenuBook fontSize="small" />
      <Typography variant="body2">
        {recipe.ingredient_count} {recipe.ingredient_count === 1 ? 'ingredient' : 'ingredients'}
      </Typography>
    </Box>
    <CardContent>
      <Typography variant="h6" gutterBottom noWrap>
        {recipe.title}
      </Typography>
    </CardContent>
  </Card>
));

export const RecipeGrid = memo(({
  recipes,
  loading,
  hasMore,
  onLoadMore,
  onRecipeClick
}: RecipeGridProps) => {
  const observer = useRef<IntersectionObserver>();
  const loadMoreRef = useCallback((node: HTMLDivElement) => {
    if (loading) return;
    
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        onLoadMore();
      }
    }, {
      rootMargin: '500px' // Start loading much earlier before reaching the bottom
    });
    
    if (node) observer.current.observe(node);
  }, [loading, hasMore, onLoadMore]);

  return (
    <Box sx={{ width: '100%', py: 4 }}>
      <Grid container spacing={3}>
        {recipes.map((recipe) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={recipe.id}>
            <RecipeCard
              recipe={recipe}
              onClick={() => onRecipeClick(recipe)}
            />
          </Grid>
        ))}
      </Grid>
      
      {/* Loading indicator and intersection observer target */}
      {(hasMore || loading) && (
        <Box 
          ref={loadMoreRef}
          sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            py: 4
          }}
        >
          {loading && <CircularProgress size={40} />}
        </Box>
      )}
    </Box>
  );
}); 