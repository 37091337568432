import React, { useState, useEffect, Suspense, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ArrowBack, Edit, Delete } from '@mui/icons-material';
import { useApi } from '../api/client';
import { useSnackbar } from '../components/Snackbar';
import { Recipe as RecipeType, ProcessedRecipeResponse } from '../types/recipe';
import { useUser } from '../contexts/UserContext';
import { fractionToDecimal, decimalToFraction } from '../utils/units';

const RecipeView = React.lazy(() => import('../components/recipe/RecipeView'));
const EditableRecipe = React.lazy(() => import('../components/recipe/EditableRecipe'));

function Recipe() {
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const api = useApi();
  const { showSnackbar } = useSnackbar();
  const { user } = useUser();
  const [recipe, setRecipe] = useState<RecipeType | null>(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [processedRecipe, setProcessedRecipe] = useState<ProcessedRecipeResponse | null>(null);
  const apiRef = useRef(api);
  const showSnackbarRef = useRef(showSnackbar);

  // Update refs when dependencies change
  useEffect(() => {
    apiRef.current = api;
    showSnackbarRef.current = showSnackbar;
  }, [api, showSnackbar]);

  useEffect(() => {
    let mounted = true;

    const fetchRecipe = async () => {
      if (!recipeId) return;
      
      try {
        const fetchedRecipe = await apiRef.current.getRecipe(recipeId);
        if (mounted) {
          // Convert quantities to fractions for display and ensure units are never null
          setRecipe({
            ...fetchedRecipe,
            ingredients: fetchedRecipe.ingredients.map(ingredient => ({
              ...ingredient,
              quantity: decimalToFraction(Number(ingredient.quantity)),
              unit: ingredient.unit || ''  // Ensure unit is never null
            }))
          });
        }
      } catch (error) {
        console.error('Error fetching recipe:', error);
        if (mounted) {
          showSnackbarRef.current('Failed to load recipe', 'error');
          navigate('/recipes');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchRecipe();

    return () => {
      mounted = false;
    };
  }, [recipeId, navigate]);

  const handleEdit = () => {
    if (!recipe) return;
    
    // For existing recipes, we already have all the data we need
    setProcessedRecipe({
      title: recipe.title,
      ingredients: recipe.ingredients.map(i => ({
        ...i,
        quantity: String(i.quantity),
        unit: i.unit || ''  // Ensure unit is never null
      })),
      instructions: recipe.instructions,
      available_units: ['cup', 'tablespoon', 'teaspoon', 'ounce', 'pound', 'gram', 'kilogram', 'milliliter', 'liter', 'pinch', 'each'],
      warnings: []
    });
    setIsEditing(true);
  };

  const handleSave = async (updatedRecipe: RecipeType) => {
    try {
      // Convert ingredient quantities to decimals before sending to API
      const recipeToSave = {
        ...updatedRecipe,
        ingredients: updatedRecipe.ingredients.map(ingredient => ({
          ...ingredient,
          quantity: fractionToDecimal(ingredient.quantity).toString()
        }))
      };

      await api.updateRecipe(recipeId!, recipeToSave);
      setRecipe(updatedRecipe); // Keep the fractions in the UI state
      setIsEditing(false);
      setProcessedRecipe(null); // Clear processed recipe data
      showSnackbar('Recipe updated successfully!', 'success');
    } catch (error) {
      console.error('Error updating recipe:', error);
      showSnackbar('Failed to update recipe', 'error');
    }
  };

  const handleDelete = async () => {
    try {
      await api.deleteRecipe(recipeId!);
      showSnackbar('Recipe deleted successfully!', 'success');
      navigate('/recipes');
    } catch (error) {
      console.error('Error deleting recipe:', error);
      showSnackbar('Failed to delete recipe', 'error');
    }
  };

  const LoadingFallback = () => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      height: '100%',
      minHeight: '200px'
    }}>
      <CircularProgress />
    </Box>
  );

  if (loading) {
    return <LoadingFallback />;
  }

  if (!recipe) {
    return null;
  }

  return (
    <Box sx={{ p: { xs: 1.5, sm: 3 } }}>
      <Button
        startIcon={<ArrowBack />}
        onClick={() => navigate('/recipes')}
        sx={{ mb: 2 }}
      >
        Back to Recipes
      </Button>

      <Card>
        <CardContent>
          <Suspense fallback={<LoadingFallback />}>
            {isEditing ? (
              <EditableRecipe
                recipe={recipe}
                processedRecipe={processedRecipe || {
                  title: recipe.title,
                  ingredients: recipe.ingredients,
                  instructions: recipe.instructions,
                  available_units: [],
                  warnings: []
                }}
                onSave={handleSave}
                setProcessedRecipe={setProcessedRecipe}
                showSnackbar={showSnackbar}
              />
            ) : (
              <RecipeView 
                recipe={recipe} 
                onEdit={recipe.is_creator ? handleEdit : undefined}
                onDelete={recipe.is_creator ? () => setDeleteDialogOpen(true) : undefined}
              />
            )}
          </Suspense>
        </CardContent>
      </Card>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Recipe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this recipe? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleDelete} 
            className="delete-button"
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Recipe; 