import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
  Chip,
  Divider,
  Stack,
  Avatar
} from '@mui/material';
import { Delete, Edit, Save, Cancel, PersonAdd } from '@mui/icons-material';
import { useUser } from '../contexts/UserContext';
import { useApi, HouseholdMember } from '../api/client';
import { useSnackbar } from '../components/Snackbar';
import { formatDate } from '../utils/date';

function Household() {
  const { user, selectedHousehold, refreshUser } = useUser();
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteRole, setInviteRole] = useState<'member' | 'admin'>('member');
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState('');
  const api = useApi();
  const { showSnackbar } = useSnackbar();

  if (!selectedHousehold || !user) {
    return null;
  }

  const currentMembers = user.current_household.members;

  const handleInviteMember = async () => {
    if (!inviteEmail) {
      showSnackbar('Please enter an email address', 'error');
      return;
    }

    try {
      await api.inviteHouseholdMember(inviteEmail, inviteRole);
      await refreshUser();
      setInviteEmail('');
      setInviteRole('member');
      showSnackbar('Invitation sent successfully', 'success');
    } catch (error) {
      console.error('Error inviting member:', error);
      showSnackbar('Failed to send invitation', 'error');
    }
  };

  const handleRemoveMember = async (memberId: string) => {
    try {
      await api.removeHouseholdMember(memberId);
      await refreshUser();
      showSnackbar('Member removed successfully', 'success');
    } catch (error) {
      console.error('Error removing member:', error);
      showSnackbar('Failed to remove member', 'error');
    }
  };

  const handleUpdateHouseholdName = async () => {
    try {
      await api.updateHouseholdName(editedName);
      await refreshUser();
      setIsEditingName(false);
      showSnackbar('Household name updated successfully', 'success');
    } catch (error) {
      console.error('Error updating household name:', error);
      showSnackbar('Failed to update household name', 'error');
    }
  };

  const startEditingName = () => {
    setEditedName(selectedHousehold.name);
    setIsEditingName(true);
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: { xs: 2, sm: 3 } }}>
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          {isEditingName ? (
            <Box sx={{ display: 'flex', gap: 1, flex: 1 }}>
              <TextField
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                size="small"
                fullWidth
              />
              <IconButton onClick={handleUpdateHouseholdName} color="primary">
                <Save />
              </IconButton>
              <IconButton onClick={() => setIsEditingName(false)}>
                <Cancel />
              </IconButton>
            </Box>
          ) : (
            <>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {selectedHousehold.name}
              </Typography>
              {selectedHousehold.is_creator && (
                <IconButton onClick={startEditingName}>
                  <Edit />
                </IconButton>
              )}
            </>
          )}
        </Box>

        {selectedHousehold.is_creator && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle1" gutterBottom>
              Invite New Member
            </Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                size="small"
                label="Email Address"
                type="email"
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
                fullWidth
              />
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Role</InputLabel>
                <Select
                  value={inviteRole}
                  label="Role"
                  onChange={(e) => setInviteRole(e.target.value as 'member' | 'admin')}
                >
                  <MenuItem value="member">Member</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                onClick={handleInviteMember}
                startIcon={<PersonAdd />}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              >
                Invite
              </Button>
            </Stack>
          </>
        )}
      </Box>

      <Typography variant="h6" gutterBottom>
        Members ({currentMembers.length})
      </Typography>

      <Stack spacing={2}>
        {currentMembers.map((member) => (
          <Paper
            key={member.email}
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'flex-start',
              gap: 2
            }}
          >
            <Avatar sx={{ bgcolor: member.is_creator ? 'primary.main' : 'grey.400' }}>
              {member.email[0].toUpperCase()}
            </Avatar>
            <Box sx={{ flex: 1, minWidth: 0 }}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1, 
                mb: 0.5,
                flexWrap: 'wrap'
              }}>
                <Typography noWrap sx={{ minWidth: 0 }}>{member.email}</Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {member.is_creator && (
                    <Chip
                      label="creator"
                      size="small"
                      color="primary"
                      sx={{ height: 20 }}
                    />
                  )}
                  <Chip
                    label={member.role}
                    size="small"
                    variant="outlined"
                    sx={{ height: 20 }}
                  />
                </Box>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Joined {formatDate(member.joined_at)}
              </Typography>
            </Box>
            {selectedHousehold.is_creator && !member.is_creator && (
              <IconButton
                onClick={() => handleRemoveMember(member.id)}
                color="error"
                size="small"
              >
                <Delete />
              </IconButton>
            )}
          </Paper>
        ))}
      </Stack>
    </Box>
  );
}

export default Household; 