import React from 'react';
import { Box, FormControl, Select, MenuItem, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useUser } from '../../contexts/UserContext';

export function HouseholdSelector() {
  const { households, selectedHousehold, setSelectedHousehold, loading } = useUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (loading || households.length <= 1) {
    return null;
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      gap: 1,
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'stretch' : 'center',
      width: isMobile ? '100%' : 'auto'
    }}>
      <Typography 
        variant="body2" 
        sx={{ 
          color: 'text.secondary',
          mb: isMobile ? 1 : 0
        }}
      >
        Household:
      </Typography>
      <FormControl 
        size="small" 
        sx={{ 
          minWidth: isMobile ? '100%' : 200
        }}
      >
        <Select
          value={selectedHousehold?.id || ''}
          onChange={(e) => {
            const household = households.find(h => h.id === e.target.value);
            if (household) {
              setSelectedHousehold(household);
            }
          }}
          sx={{
            '& .MuiSelect-select': {
              py: 0.5
            }
          }}
        >
          {households.map((household) => (
            <MenuItem key={household.id} value={household.id}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography>{household.name}</Typography>
                {household.is_creator && (
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      bgcolor: 'primary.main',
                      color: 'white',
                      px: 0.5,
                      borderRadius: 0.5,
                      fontSize: '0.7rem'
                    }}
                  >
                    Creator
                  </Typography>
                )}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
} 