import React from 'react'
import { Box, AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, Avatar, Menu, MenuItem, Button, ListItemButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { HouseholdSelector } from './household/HouseholdSelector';

const LOGO_HEIGHT = 40;

interface LayoutProps {
  children: ReactNode;
}

function Layout({ children }: LayoutProps) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user, logout, loginWithRedirect } = useAuth0();

  const menuItems = [
    { text: 'Pantry', path: '/' },
    { text: 'Recipes', path: '/recipes' },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed">
        <Toolbar>
          {isAuthenticated && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box 
            sx={{ 
              flexGrow: 1, 
              display: 'flex', 
              alignItems: 'center',
              cursor: 'pointer'
            }}
            onClick={() => navigate('/')}
          >
            <img
              src="/logo.svg"
              alt="MyKtchn Logo"
              style={{
                height: LOGO_HEIGHT,
                width: 'auto',
                marginRight: '8px'
              }}
            />
          </Box>

          {/* Desktop Navigation */}
          {isAuthenticated && (
            <>
              <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 2, mr: 2 }}>
                {menuItems.map((item) => {
                  const isSelected = location.pathname === item.path;
                  return (
                    <Button
                      key={item.path}
                      onClick={() => navigate(item.path)}
                      sx={{
                        color: 'black',
                        position: 'relative',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        },
                        '&::after': isSelected ? {
                          content: '""',
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: '100%',
                          height: '2px',
                          backgroundColor: '#d84315'
                        } : undefined
                      }}
                    >
                      {item.text}
                    </Button>
                  );
                })}
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'block' }, mr: 2 }}>
                <HouseholdSelector />
              </Box>
            </>
          )}

          {isAuthenticated ? (
            <>
              <IconButton onClick={handleMenuOpen} color="inherit">
                <Avatar src={user?.picture} alt={user?.name} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem disabled>
                  <Typography variant="body2">{user?.email}</Typography>
                </MenuItem>
                <MenuItem onClick={() => {
                  handleMenuClose();
                  navigate('/household');
                }}>
                  My Household
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button 
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.08)'
                  }
                }}
                onClick={() => loginWithRedirect()}
              >
                Log In
              </Button>
              <Button 
                sx={{
                  bgcolor: '#d84315',
                  color: 'white',
                  '&:hover': {
                    bgcolor: '#bf360c',
                    transform: 'translateY(-2px)',
                    transition: 'all 0.2s'
                  }
                }}
                variant="contained"
                onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
              >
                Sign Up
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      {isAuthenticated && (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{ 
            display: { sm: 'none' },
            '& .MuiDrawer-paper': {
              width: '85%',
              maxWidth: '360px',
              boxSizing: 'border-box'
            }
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          <List>
            <ListItem 
              sx={{ 
                px: 2,
                py: 2,
                borderBottom: 1,
                borderColor: 'divider'
              }}
            >
              <HouseholdSelector />
            </ListItem>
            {menuItems.map((item) => {
              const isSelected = location.pathname === item.path;
              return (
                <ListItem 
                  key={item.path} 
                  disablePadding
                  onClick={() => {
                    navigate(item.path);
                    handleDrawerToggle();
                  }}
                >
                  <ListItemButton
                    selected={isSelected}
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: 'rgba(216, 67, 21, 0.08)',
                        '&:hover': {
                          backgroundColor: 'rgba(216, 67, 21, 0.12)'
                        }
                      }
                    }}
                  >
                    <ListItemText 
                      primary={item.text}
                      sx={{
                        '& .MuiTypography-root': {
                          fontWeight: isSelected ? 600 : 400
                        }
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: '100%',
          mt: `${LOGO_HEIGHT + 16}px`
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default Layout; 