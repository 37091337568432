import React, { useState, useEffect, useRef, Suspense, createContext, useContext } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Button,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  SwipeableDrawer,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  InputAdornment
} from '@mui/material';
import { AddPhotoAlternate, Close, Edit, Delete, Check, Search, Add } from '@mui/icons-material';
import { useApi } from '../api/client';
import type { InventoryIngredient, RawIngredient, UpdateIngredientParams } from '../api/client';
import { useSnackbar } from '../components/Snackbar';
import { ImageUploader } from '../components/shared/ImageUploader';
import { EditableIngredients } from '../components/ingredient/EditableIngredients';
import type { Ingredient } from '../types/ingredient';
import type { ProcessedIngredient, ProcessedIngredientsResponse } from '../types/processedIngredients';
import { formatDate } from '../utils/date';
import { resizeImage } from '../utils/imageProcessing';
import { pluralizeUnit, fractionToDecimal, decimalToFraction } from '../utils/units';
import { EditableIngredientFields } from '../components/ingredient/EditableIngredientFields';
import { useDebounce } from '../utils/useDebounce';
import { useUser } from '../contexts/UserContext';

const PhotoCapture = React.lazy(() => import('../components/PhotoCapture').then(module => ({ default: module.default })));

// Create the InventoryContext
interface InventoryContextType {
  availableUnits: string[];
}

const InventoryContext = createContext<InventoryContextType>({ availableUnits: [] });

interface ImageFile {
  id: string;
  url: string;
  file: File | Blob;
}

// Update the InventoryIngredient type to match the API response
interface ExtendedInventoryIngredient extends Omit<InventoryIngredient, 'unit'> {
  unit: string | null;
}

// Define the type for sort directions
type Order = 'asc' | 'desc';

// Define the type for sortable columns
interface HeadCell {
  id: 'ingredient_name' | 'amount' | 'unit' | 'last_updated';
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'ingredient_name', label: 'Ingredient', numeric: false },
  { id: 'amount', label: 'Amount', numeric: true },
  { id: 'unit', label: 'Unit', numeric: false },
  { id: 'last_updated', label: 'Last Updated', numeric: false },
];

interface InlineEditRowProps {
  ingredient: ExtendedInventoryIngredient;
  onSave: (params: UpdateIngredientParams) => void;
  onCancel: () => void;
  availableUnits: string[];
}

const InlineEditRow = ({ ingredient, onSave, onCancel, availableUnits }: InlineEditRowProps) => {
  const [editedIngredient, setEditedIngredient] = useState({
    quantity: decimalToFraction(ingredient.amount),
    unit: ingredient.unit || '',
    name: ingredient.ingredient_name
  });
  const [error, setError] = useState('');

  const handleUpdate = (field: 'quantity' | 'unit' | 'name', value: string) => {
    setEditedIngredient(prev => ({ ...prev, [field]: value }));
    if (field === 'quantity') {
      try {
        const numAmount = fractionToDecimal(value);
        if (!value || numAmount <= 0) {
          setError('Please enter a valid amount');
        } else {
          setError('');
        }
      } catch (e) {
        // Allow partial input while typing
        if (value !== '') {
          setError('Please enter a valid amount');
        }
      }
    }
  };

  const handleSave = () => {
    try {
      const numAmount = fractionToDecimal(editedIngredient.quantity);
    if (isNaN(numAmount) || numAmount <= 0) {
      setError('Please enter a valid amount');
      return;
    }

    onSave({
      ingredient_name: ingredient.ingredient_name,
      amount: numAmount,
        unit: editedIngredient.unit || undefined
    });
    } catch (e) {
      setError('Please enter a valid amount');
    }
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {ingredient.ingredient_name}
      </TableCell>
      <TableCell align="right">
        <TextField
          value={editedIngredient.quantity}
          onChange={(e) => handleUpdate('quantity', e.target.value)}
          size="small"
          sx={{ width: '80px' }}
          error={!!error}
          helperText={error}
          inputProps={{ style: { textAlign: 'right' } }}
          placeholder="e.g. 1/2"
        />
      </TableCell>
      <TableCell>
        <TextField
          select
          value={editedIngredient.unit}
          onChange={(e) => handleUpdate('unit', e.target.value)}
          size="small"
          sx={{ width: '120px' }}
        >
          <MenuItem value="">None</MenuItem>
          {availableUnits?.map((unit: string) => (
            <MenuItem key={unit} value={unit}>
              {pluralizeUnit(unit, fractionToDecimal(editedIngredient.quantity) || 2)}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell>{formatDate(ingredient.last_updated)}</TableCell>
      <TableCell align="right">
        <IconButton
          size="small"
          onClick={handleSave}
          disabled={!!error}
          sx={{ mr: 1 }}
          color="primary"
        >
          <Check />
        </IconButton>
        <IconButton
          size="small"
          onClick={onCancel}
          color="error"
        >
          <Close />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

interface SwipeableRowProps {
  ingredient: ExtendedInventoryIngredient;
  onEdit: (params: UpdateIngredientParams) => Promise<void>;
  onDelete: (ingredient_name: string) => void;
}

const SwipeableRow = ({ ingredient, onEdit, onDelete }: SwipeableRowProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { availableUnits } = useContext(InventoryContext);

  const handleSave = async (params: UpdateIngredientParams) => {
    await onEdit(params);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <InlineEditRow
        ingredient={ingredient}
        onSave={handleSave}
        onCancel={() => setIsEditing(false)}
        availableUnits={availableUnits}
      />
    );
  }

  return (
    <TableRow
      sx={{
        position: 'relative',
        cursor: isMobile ? 'grab' : 'default',
        '&:hover': {
          bgcolor: isMobile ? 'action.hover' : 'inherit'
        }
      }}
    >
      <TableCell component="th" scope="row">
        {ingredient.ingredient_name}
      </TableCell>
      <TableCell align="right">{decimalToFraction(ingredient.amount)}</TableCell>
      <TableCell>{ingredient.unit ? pluralizeUnit(ingredient.unit, ingredient.amount) : ''}</TableCell>
      <TableCell>{formatDate(ingredient.last_updated)}</TableCell>
      <TableCell align="right">
        <IconButton
          edge="end"
          className="edit-button"
          onClick={() => setIsEditing(true)}
        >
          <Edit />
        </IconButton>
        <IconButton
          edge="end"
          className="delete-button"
          onClick={() => onDelete(ingredient.ingredient_name)}
        >
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

interface MobileInventoryItemProps {
  ingredient: ExtendedInventoryIngredient;
  onEdit: (params: UpdateIngredientParams) => Promise<void>;
  onDelete: (ingredient_name: string) => void;
  availableUnits: string[];
}

const MobileInventoryItem = ({ ingredient, onEdit, onDelete, availableUnits }: MobileInventoryItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedIngredient, setEditedIngredient] = useState({
    quantity: decimalToFraction(ingredient.amount),
    unit: ingredient.unit || '',
    name: ingredient.ingredient_name
  });
  const [error, setError] = useState('');
  const [swipeOffset, setSwipeOffset] = useState(0);
  const touchStartX = useRef<number | null>(null);
  const swipeElement = useRef<HTMLDivElement>(null);
  const actionWidth = 160; // Increased width for better touch targets

  // Reset state when ingredient changes
  useEffect(() => {
    setEditedIngredient({
      quantity: decimalToFraction(ingredient.amount),
      unit: ingredient.unit || '',
      name: ingredient.ingredient_name
    });
    setError('');
    setSwipeOffset(0);
    if (swipeElement.current) {
      swipeElement.current.style.transform = 'translateX(0)';
    }
  }, [ingredient]);

  const handleTouchStart = (e: React.TouchEvent) => {
    if (isEditing) return;
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (isEditing || touchStartX.current === null || !swipeElement.current) return;
    
    const currentX = e.touches[0].clientX;
    const diff = touchStartX.current - currentX;
    const newOffset = Math.max(0, Math.min(diff, actionWidth));
    
    setSwipeOffset(newOffset);
    swipeElement.current.style.transform = `translateX(-${newOffset}px)`;
  };

  const handleTouchEnd = () => {
    if (isEditing || !swipeElement.current) return;
    
    touchStartX.current = null;
    const threshold = actionWidth / 2;
    
    if (swipeOffset > threshold) {
      setSwipeOffset(actionWidth);
      swipeElement.current.style.transform = `translateX(-${actionWidth}px)`;
    } else {
      setSwipeOffset(0);
      swipeElement.current.style.transform = 'translateX(0)';
    }
  };

  const handleUpdate = (field: 'quantity' | 'unit' | 'name', value: string) => {
    setEditedIngredient(prev => ({ ...prev, [field]: value }));
    if (field === 'quantity') {
      try {
        const numAmount = fractionToDecimal(value);
        if (!value || numAmount <= 0) {
          setError('Please enter a valid amount');
        } else {
          setError('');
        }
      } catch (e) {
        // Allow partial input while typing
        if (value !== '') {
          setError('Please enter a valid amount');
        }
      }
    }
  };

  const handleSave = async () => {
    try {
      const numAmount = fractionToDecimal(editedIngredient.quantity);
    if (isNaN(numAmount) || numAmount <= 0) {
      setError('Please enter a valid amount');
      return;
    }

    await onEdit({
      ingredient_name: ingredient.ingredient_name,
      amount: numAmount,
        unit: editedIngredient.unit || undefined
    });
    setIsEditing(false);
    } catch (e) {
      setError('Please enter a valid amount');
    }
  };

  const handleCancel = () => {
    setEditedIngredient({
      quantity: decimalToFraction(ingredient.amount),
      unit: ingredient.unit || '',
      name: ingredient.ingredient_name
    });
    setError('');
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <Box sx={{ 
        p: 2, 
        bgcolor: 'background.paper',
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 1 }}>
            Edit {ingredient.ingredient_name}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              label="Amount"
              value={editedIngredient.quantity}
              onChange={(e) => handleUpdate('quantity', e.target.value)}
              error={!!error}
              helperText={error}
              size="small"
              sx={{ width: '45%' }}
            />
            <TextField
              select
              label="Unit"
              value={editedIngredient.unit}
              onChange={(e) => handleUpdate('unit', e.target.value)}
              size="small"
              sx={{ width: '45%' }}
            >
              <MenuItem value="">None</MenuItem>
              {availableUnits?.map((unit: string) => (
                <MenuItem key={unit} value={unit}>
                  {pluralizeUnit(unit, fractionToDecimal(editedIngredient.quantity) || 2)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!!error}
            fullWidth
              sx={{
                height: '48px'
              }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={handleCancel}
            fullWidth
              sx={{
                height: '48px'
              }}
          >
            Cancel
          </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        position: 'relative',
        overflow: 'hidden',
        bgcolor: 'background.paper',
        '&:active': {
          bgcolor: 'action.selected'
        }
      }}
    >
      {/* Action buttons container */}
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          height: '100%',
          width: `${actionWidth}px`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 1,
          px: 2,
          bgcolor: 'background.paper',
          borderLeft: '1px solid',
          borderColor: 'divider',
        }}
      >
        <IconButton
          onClick={() => setIsEditing(true)}
          sx={{
            bgcolor: 'action.hover',
            color: 'primary.main',
            '&:hover': { bgcolor: 'action.selected' }
          }}
        >
          <Edit />
        </IconButton>
        <IconButton
          onClick={() => onDelete(ingredient.ingredient_name)}
          sx={{
            bgcolor: 'action.hover',
            color: 'error.main',
            '&:hover': { bgcolor: 'action.selected' }
          }}
        >
          <Delete />
        </IconButton>
      </Box>

      {/* Swipeable content */}
      <Box
        ref={swipeElement}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        sx={{
          position: 'relative',
          bgcolor: 'background.paper',
          transition: swipeOffset === 0 ? 'transform 0.2s ease-out' : 'none',
          touchAction: 'pan-y pinch-zoom',
        }}
      >
        <ListItem
          sx={{
            py: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: 1
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
            {ingredient.ingredient_name}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body1">
              {decimalToFraction(ingredient.amount)} {ingredient.unit ? pluralizeUnit(ingredient.unit, ingredient.amount) : ''}
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary', ml: 'auto' }}>
              Updated: {formatDate(ingredient.last_updated)}
            </Typography>
          </Box>
        </ListItem>
      </Box>
    </Box>
  );
};

// Add this interface after other interfaces
interface DeleteDialogProps {
  open: boolean;
  ingredient: string;
  onClose: () => void;
  onConfirm: () => void;
}

function DeleteDialog({ open, ingredient, onClose, onConfirm }: DeleteDialogProps) {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxWidth: 400
        }
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h6" component="div" sx={{ fontWeight: 'medium' }}>
          Delete Ingredient
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <Typography variant="body1">
          Are you sure you want to remove <strong>{ingredient}</strong> from your kitchen pantry?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{ minWidth: 100 }}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          className="delete-button"
          sx={{ minWidth: 100 }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Pantry() {
  // Pantry state
  const [loading, setLoading] = useState(false);
  const [ingredients, setIngredients] = useState<ExtendedInventoryIngredient[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(-1); // Start at -1 so first increment sets it to 0
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ExtendedInventoryIngredient>('ingredient_name');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState<string | null>(null);
  const loadingRef = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver>();
  const [totalCount, setTotalCount] = useState(0);
  const [availableUnits, setAvailableUnits] = useState<string[]>([]);

  // Image upload state
  const [image, setImage] = useState<ImageFile | null>(null);
  const [showCamera, setShowCamera] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [processedIngredients, setProcessedIngredients] = useState<ProcessedIngredientsResponse | null>(null);
  const [editingIngredients, setEditingIngredients] = useState<Ingredient[] | null>(null);
  const [addedToPantry, setAddedToPantry] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const api = useApi();
  const { showSnackbar } = useSnackbar();
  const { selectedHousehold } = useUser();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const [manualIngredient, setManualIngredient] = useState({
    quantity: '',
    unit: '',
    name: ''
  });

  const fetchPantry = async (pageToFetch: number = 0) => {
    try {
      setLoading(true);
      const response = await api.getIngredients({
        page: pageToFetch,
        sort_by: orderBy,
        sort_order: order,
        per_page: 20,
        search: debouncedSearchQuery
      });

      if (pageToFetch === 0) {
        setIngredients(response.ingredients);
      } else {
        setIngredients(prev => [...prev, ...response.ingredients]);
      }
      
      setHasMore(response.ingredients.length === 20);
      setPage(pageToFetch);
      setTotalCount(response.pagination.total);
      setAvailableUnits(response.available_units);
    } catch (error) {
      console.error('Error fetching pantry:', error);
      showSnackbar('Failed to load pantry', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Add effect to handle search and sort changes
  useEffect(() => {
    setPage(-1); // Reset pagination
    setIngredients([]); // Clear current ingredients
    fetchPantry(0); // Fetch first page with search
  }, [debouncedSearchQuery, orderBy, order, selectedHousehold?.id]); // Replace householdChangeCount with selectedHousehold?.id

  // Infinite scroll observer
  useEffect(() => {
    if (loading) return;

    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && page >= 0) {
        fetchPantry(page + 1);
      }
    }, options);

    if (loadingRef.current) {
      observer.current.observe(loadingRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, hasMore, page]);

  const handleChangePage = (_: unknown, newPage: number) => {
    fetchPantry(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    fetchPantry(0);
  };

  const handleRequestSort = (property: keyof ExtendedInventoryIngredient) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Image upload handlers
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage({
          id: crypto.randomUUID(),
          url: reader.result as string,
          file: file
        });
      };
      reader.readAsDataURL(file);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handlePhotoCapture = (imageUrl: string) => {
    const base64Response = imageUrl.split(',')[1];
    const binaryString = window.atob(base64Response);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'image/jpeg' });

    setImage({
      id: crypto.randomUUID(),
      url: imageUrl,
      file: blob
    });
  };

  const handleDeleteImage = () => {
    setImage(null);
    setProcessedIngredients(null);
  };

  const handleUploadImage = async () => {
    if (!image) {
      showSnackbar('Please add an image', 'warning');
      return;
    }

    setIsUploading(true);
    try {
      const resizedImage = await resizeImage(image.file);
      const result = await api.uploadIngredients(resizedImage);
      
      // Convert the raw API response to our processed format
      const processedResponse: ProcessedIngredientsResponse = {
        ingredients: (result.ingredients as ProcessedIngredient[]).map(ing => ({
          name: ing.name,
          quantity: ing.quantity?.toString() || '',
          unit: ing.unit || '',
          warning: ing.warning
        })),
        available_units: result.available_units || [],
        warnings: result.warnings
      };
      
      setProcessedIngredients(processedResponse);
      setEditingIngredients(processedResponse.ingredients.map((i: ProcessedIngredient) => ({
        id: crypto.randomUUID(),
        name: i.name,
        quantity: i.quantity?.toString() || '',
        unit: i.unit || ''
      })));
      showSnackbar('Ingredients processed successfully!', 'success');
    } catch (error) {
      console.error('Error processing ingredients:', error);
      showSnackbar('Failed to process ingredients', 'error');
    } finally {
      setIsUploading(false);
    }
  };

  const handleFinalizeIngredients = async (ingredients: Ingredient[]) => {
    try {
      // Store the final state of ingredients that were actually added
      const finalizedIngredients = ingredients.map(ing => ({
        name: ing.name,
        quantity: ing.quantity,
        unit: ing.unit
      }));

      setProcessedIngredients({
        ...processedIngredients!,
        ingredients: finalizedIngredients
      });
    setEditingIngredients(null);
    setAddedToPantry(true);
    // Refresh the pantry list
      await fetchPantry();
    } catch (error) {
      console.error('Error finalizing ingredients:', error);
      showSnackbar('Failed to save ingredients', 'error');
    }
  };

  const handleEdit = async (params: UpdateIngredientParams) => {
    try {
      await api.updateIngredient(params);
      showSnackbar('Ingredient updated successfully', 'success');
      fetchPantry();
    } catch (error) {
      console.error('Error updating ingredient:', error);
      showSnackbar('Failed to update ingredient', 'error');
    }
  };

  const handleDelete = async (ingredient_name: string) => {
    setDeleteDialogOpen(true);
    setSelectedIngredient(ingredient_name);
  };

  const handleConfirmDelete = async () => {
    try {
      await api.deleteIngredient(selectedIngredient || '');
      showSnackbar('Ingredient deleted successfully', 'success');
      // Reset to first page and clear existing ingredients
      setIngredients([]);
      setPage(0);
      setHasMore(true);
      fetchPantry(0);
    } catch (error) {
      console.error('Error deleting ingredient:', error);
      showSnackbar('Failed to delete ingredient', 'error');
    } finally {
      setDeleteDialogOpen(false);
      setSelectedIngredient(null);
    }
  };

  const handleAddManualIngredient = async () => {
    try {
      const numAmount = fractionToDecimal(manualIngredient.quantity);
      if (isNaN(numAmount) || numAmount <= 0) {
        showSnackbar('Please enter a valid amount', 'error');
        return;
      }

      await api.addIngredients([{
        name: manualIngredient.name,
        quantity: manualIngredient.quantity,
        unit: manualIngredient.unit || undefined
      }]);
      showSnackbar('Ingredient added successfully', 'success');
      setManualIngredient({ quantity: '', unit: '', name: '' });
      fetchPantry();
    } catch (error) {
      console.error('Error adding ingredient:', error);
      showSnackbar('Failed to add ingredient', 'error');
    }
  };

  return (
    <InventoryContext.Provider value={{ availableUnits }}>
      <Box sx={{ p: { xs: 1.5, sm: 3 } }}>
        <Typography variant="h4" gutterBottom>
          Kitchen Pantry
        </Typography>

        {/* Add Ingredients Section */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Add Ingredients to Your Kitchen
          </Typography>
          <Typography variant="body1" gutterBottom>
            Upload, take a photo, or manually add ingredients.
          </Typography>
          
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 1.5, sm: 2 }, 
            mb: 3,
            alignItems: { xs: 'stretch', sm: 'center' },
            justifyContent: { xs: 'flex-start', sm: 'center' }
          }}>
            {!processedIngredients ? (
              <ImageUploader
                isUploading={isUploading}
                onFileSelect={handleFileUpload}
                onCameraClick={() => setShowCamera(true)}
                onProcessClick={handleUploadImage}
                showProcessButton={!!image && !processedIngredients}
                processButtonText="Process Ingredients"
              />
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddPhotoAlternate />}
                onClick={() => {
                  setImage(null);
                  setProcessedIngredients(null);
                  setEditingIngredients(null);
                }}
                fullWidth
                sx={{ height: { xs: '56px', sm: '40px' } }}
              >
                Process More Ingredients
              </Button>
            )}
          </Box>

          <Suspense fallback={<CircularProgress />}>
            <PhotoCapture
              open={showCamera}
              onClose={() => setShowCamera(false)}
              onPhotoCapture={handlePhotoCapture}
            />
          </Suspense>

          {image && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={processedIngredients ? 6 : 12}>
                <Card sx={{ mb: 3 }}>
                  <Box sx={{ position: 'relative' }}>
                    <img
                      src={image.url}
                      alt="Ingredient preview"
                      style={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: '500px',
                        objectFit: 'contain'
                      }}
                    />
                    {!isUploading && !processedIngredients && (
                      <IconButton
                        onClick={handleDeleteImage}
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          bgcolor: 'rgba(0, 0, 0, 0.5)',
                          color: 'white',
                          '&:hover': {
                            bgcolor: 'rgba(0, 0, 0, 0.7)',
                          },
                        }}
                      >
                        <Close />
                      </IconButton>
                    )}
                  </Box>
                </Card>
              </Grid>

              {processedIngredients && (
                <Grid item xs={12} md={6}>
                  <Card sx={{ height: '100%' }}>
                    <CardContent>
                      {editingIngredients ? (
                        <EditableIngredients
                          ingredients={editingIngredients}
                          processedIngredients={processedIngredients}
                          onSave={handleFinalizeIngredients}
                          setProcessedIngredients={setProcessedIngredients}
                          showSnackbar={showSnackbar}
                        />
                      ) : (
                        <>
                          <Typography variant="h6" gutterBottom>
                            {addedToPantry ? 'Added to Kitchen Pantry' : 'Detected Ingredients'}
                          </Typography>
                          {Array.isArray(processedIngredients.ingredients) ? (
                            <>
                              {processedIngredients.ingredients.map((ingredient: ProcessedIngredient, index: number) => {
                                const numQuantity = parseFloat(ingredient.quantity) || 0;
                                return (
                                <Typography key={index} variant="body1" sx={{ mb: 1 }}>
                                    • {decimalToFraction(numQuantity)} {ingredient.unit ? pluralizeUnit(ingredient.unit, numQuantity) : ''} {ingredient.name}
                                </Typography>
                                );
                              })}
                              
                              {!addedToPantry && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => setEditingIngredients(processedIngredients.ingredients.map((i: ProcessedIngredient) => ({
                                    id: crypto.randomUUID(),
                                    name: i.name,
                                    quantity: i.quantity?.toString() || '',
                                    unit: i.unit || ''
                                  })))}
                                  fullWidth
                                  sx={{ mt: 2 }}
                                >
                                  Edit Ingredients
                                </Button>
                              )}
                            </>
                          ) : (
                            <Typography color="error">
                              No ingredients were detected. Please try again with a clearer image.
                            </Typography>
                          )}
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          )}
        </Box>

        {/* Pantry List/Table */}
          {isMobile ? (
          <Box sx={{ 
            width: '100%', 
            bgcolor: 'background.paper',
            borderRadius: { xs: 0, sm: 1 },
            overflow: 'hidden',
            mx: { xs: -1.5, sm: 0 }
          }}>
            {/* Manual Ingredient Entry Form */}
            <Box sx={{ 
              p: 2, 
              borderBottom: 1, 
              borderColor: 'divider'
            }}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                gap: 2
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  gap: 2,
                  width: '100%'
                }}>
                  <TextField
                    size="small"
                    label="Amount"
                    value={manualIngredient.quantity}
                    onChange={(e) => setManualIngredient(prev => ({ ...prev, quantity: e.target.value }))}
                    placeholder="e.g. 1/2"
                    sx={{ width: '45%' }}
                  />
                <TextField
                  select
                  size="small"
                    label="Unit"
                    value={manualIngredient.unit}
                    onChange={(e) => setManualIngredient(prev => ({ ...prev, unit: e.target.value }))}
                    sx={{ width: '45%' }}
                  >
                    <MenuItem value="">None</MenuItem>
                    {availableUnits?.map((unit: string) => (
                      <MenuItem key={unit} value={unit}>
                        {pluralizeUnit(unit, fractionToDecimal(manualIngredient.quantity) || 2)}
                    </MenuItem>
                  ))}
                </TextField>
                </Box>
                <TextField
                  size="small"
                  label="Ingredient Name"
                  value={manualIngredient.name}
                  onChange={(e) => setManualIngredient(prev => ({ ...prev, name: e.target.value.toLowerCase() }))}
                  placeholder="Enter ingredient name"
                  fullWidth
                />
                <Button
                  variant="contained"
                  onClick={handleAddManualIngredient}
                  disabled={!manualIngredient.name.trim() || !manualIngredient.quantity.trim()}
                  startIcon={<Add />}
                  sx={{ 
                    height: '48px',
                    '&.Mui-disabled': {
                      bgcolor: 'action.disabledBackground',
                      color: 'text.disabled'
                    }
                  }}
                >
                  Add
                </Button>
              </Box>
            </Box>

            {/* Search field */}
            <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Search ingredients in your pantry..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: searchQuery ? (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search"
                        onClick={() => setSearchQuery('')}
                        edge="end"
                        size="small"
                      >
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ) : null
                }}
              />
            </Box>

            {/* Mobile Ingredients List */}
            <Box sx={{ bgcolor: 'background.paper' }}>
              <List sx={{ 
                width: '100%',
                p: 0
              }}>
                {ingredients.map((ingredient) => (
                    <MobileInventoryItem
                      key={ingredient.ingredient_name}
                      ingredient={ingredient}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                      availableUnits={availableUnits}
                    />
                ))}
                {ingredients.length === 0 && searchQuery && (
                  <Box sx={{ p: 3, textAlign: 'center' }}>
                    <Typography color="text.secondary">
                      No ingredients found matching "{searchQuery}"
                    </Typography>
                  </Box>
                )}
              </List>
              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              )}
              <div ref={loadingRef} style={{ height: '20px' }} />
              {!hasMore && ingredients.length > 0 && (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="body2" color="text.secondary">
                    No more ingredients to load
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Paper sx={{ 
            width: '100%', 
            overflow: 'hidden',
            bgcolor: 'background.paper'
          }}>
            {/* Desktop Manual Ingredient Entry Form */}
            <Box sx={{ 
              p: 2, 
              borderBottom: 1, 
              borderColor: 'divider'
            }}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  gap: 2,
                  width: '100%',
                  flexDirection: { xs: 'column', sm: 'row' }
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    gap: 2,
                    width: '100%'
                  }}>
                    <TextField
                      size="small"
                      label="Amount"
                      value={manualIngredient.quantity}
                      onChange={(e) => setManualIngredient(prev => ({ ...prev, quantity: e.target.value }))}
                      placeholder="e.g. 1/2"
                      sx={{ 
                        width: { xs: '45%', sm: '120px' }
                      }}
                    />
                    <TextField
                      select
                      size="small"
                      label="Unit"
                      value={manualIngredient.unit}
                      onChange={(e) => setManualIngredient(prev => ({ ...prev, unit: e.target.value }))}
                      sx={{ width: { xs: '45%', sm: '140px' } }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {availableUnits?.map((unit: string) => (
                        <MenuItem key={unit} value={unit}>
                          {pluralizeUnit(unit, fractionToDecimal(manualIngredient.quantity) || 2)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <TextField
                    size="small"
                    label="Ingredient Name"
                    value={manualIngredient.name}
                    onChange={(e) => setManualIngredient(prev => ({ ...prev, name: e.target.value.toLowerCase() }))}
                    placeholder="Enter ingredient name"
                    sx={{ width: '100%' }}
                  />
                </Box>
                <Button
                  variant="contained"
                  onClick={handleAddManualIngredient}
                  disabled={!manualIngredient.name.trim() || !manualIngredient.quantity.trim()}
                  startIcon={<Add />}
                  sx={{ 
                    height: { xs: '48px', sm: '40px' },
                    '&.Mui-disabled': {
                      bgcolor: 'action.disabledBackground',
                      color: 'text.disabled'
                    }
                  }}
                >
                  Add
                </Button>
              </Box>
            </Box>

            {/* Search field */}
            <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Search ingredients in your pantry..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: searchQuery ? (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search"
                        onClick={() => setSearchQuery('')}
                        edge="end"
                        size="small"
                      >
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ) : null
                }}
              />
            </Box>

            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => handleRequestSort(headCell.id as keyof ExtendedInventoryIngredient)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ingredients.map((ingredient) => (
                      <SwipeableRow
                        key={ingredient.ingredient_name}
                        ingredient={ingredient}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                      />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {loading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress size={24} />
              </Box>
            )}
            <div ref={loadingRef} style={{ height: '20px' }} />
            {!hasMore && ingredients.length > 0 && (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  No more ingredients to load
                </Typography>
              </Box>
            )}
        </Paper>
        )}

        {/* Add DeleteDialog component */}
        <DeleteDialog
          open={deleteDialogOpen}
          ingredient={selectedIngredient || ''}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirm={handleConfirmDelete}
        />
      </Box>
    </InventoryContext.Provider>
  );
}

export default Pantry; 